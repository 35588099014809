import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import authenticationRequired from '@middleware/authentication-required';
import checkPublicView from '@middleware/check-public-view';
import hubOnly from '@middleware/hub-only';
import organisationRequired from '@middleware/organisation-required';
import { PageNames } from '@viewModels/enums';

// Layouts
import DefaultLayout from '@layouts/DefaultLayout.vue';
import ErrorLayout from '@layouts/ErrorLayout.vue';

// Cameras
import Cameras from '@pages/admin/cameras.vue';
import CameraCreate from '@pages/admin/cameras/create.vue';
import CameraProvision from '@pages/admin/cameras/provision.vue';
import CameraRegister from '@pages/admin/cameras/register.vue';
import CameraUnregistered from '@pages/admin/cameras/unregistered.vue';
import Camera from '@pages/admin/cameras/[id].vue';
import CameraEdit from '@pages/admin/cameras/[id]/camera-edit.vue';
import CameraApi from '@pages/admin/cameras/[id]/camera-api.vue';
import CameraLogs from '@pages/admin/cameras/[id]/camera-logs.vue';
import CameraNetwork from '@pages/admin/cameras/[id]/camera-network.vue';
import CameraPower from '@pages/admin/cameras/[id]/camera-power.vue';
import CameraSchedule from '@pages/admin/cameras/[id]/camera-schedule.vue';
import CameraSettings from '@pages/admin/cameras/[id]/camera-settings.vue';
import CameraStatus from '@pages/admin/cameras/[id]/camera-status.vue';

// Login & Registration
import ChangePassword from '@pages/change-password.vue';
import Forgot from '@pages/forgot.vue';
import Login from '@pages/login.vue';
import Logout from '@pages/logout.vue';
import Registration from '@pages/register.vue';
import RegistrationSuccess from '@pages/register-success.vue';

// Admin
import OrganisationsDirectory from '@pages/admin/organisations.vue';
import Organisation from '@pages/admin/organisations/[id].vue';
import OrganisationSettings from '@pages/admin/organisations/[id]/edit.vue';
import AdminClientSettings from '@pages/admin/clients/[id]/edit.vue';
import AdminClientCreation from '@pages/admin/clients/create.vue';
import AdminProjectSettings from '@pages/admin/projects/[id]/edit.vue';
import AdminProjectCreation from '@pages/admin/projects/create.vue';
import AdminClients from '@pages/admin/clients.vue';
import AdminTransfers from '@pages/admin/transfers.vue';
import AdminTransferCreation from '@pages/admin/transfers/create.vue';
import AdminTransferSettings from '@pages/admin/transfers/[id]/edit.vue';
import AdminProjects from '@pages/admin/projects.vue';
import AdminViews from '@pages/admin/views.vue';
import UsersDirectory from '@pages/admin/users.vue';
import User from '@pages/admin/users/[id].vue';
import UserEdit from '@pages/admin/users/[id]/user-edit.vue';
import ManageUserRoles from '@pages/admin/users/[id]/manage-roles.vue';
import UserInvitation from '@pages/admin/users/invite.vue';
import Events from '@pages/admin/events.vue';

// Videos
import VideoDirectory from '@pages/videos.vue';
import NewVideo from '@pages/videos/new-video.vue';

// Views
import Views from '@pages/views.vue';
import ViewMap from '@pages/views/view-map.vue';
import ViewWallboard from '@pages/views/view-wallboard.vue';
import ViewNew from '@pages/admin/views/view-new.vue';
import View from '@pages/views/[id].vue';
import PublicViewViewer from '@pages/views/public/[id].vue';
import ViewDownloads from '@pages/views/[id]/view-downloads.vue';
import ViewGallery from '@pages/views/[id]/view-gallery.vue';
import ViewVideos from '@pages/views/[id]/view-videos.vue';
import ViewViewer from '@pages/views/[id]/view-viewer.vue';
import ViewPreview from '@pages/admin/views/[id]/view-preview.vue';
import ViewTransfers from '@pages/admin/views/[id]/view-transfers.vue';
import ViewTransfersCreate from '@pages/admin/views/[id]/view-transfers-create.vue';
import ViewEdit from '@pages/admin/views/[id]/view-edit.vue';

// Other
import Account from '@pages/account.vue';
import WelcomePage from '@pages/welcome-page.vue';



const routes: RouteRecordRaw[] = [
  {
    path: '/p/:code',
    redirect: {
      name: PageNames.CameraProvision,
    },
  },
  {
    path: '/login',
    name: PageNames.Login,
    component: Login,
    meta: {
      public: true,
      title: 'Login',
      noIndex: true,
    },
  },
  {
    path: '/logout',
    name: PageNames.Logout,
    component: Logout,
    meta: {
      public: true,
      title: 'Logout',
      noIndex: true,
    },
  },
  {
    path: '/register',
    name: PageNames.Register,
    component: Registration,
    meta: {
      public: true,
      title: 'Register',
      noIndex: true,
    },
  },
  {
    path: '/register-success',
    name: PageNames.RegisterSuccess,
    component: RegistrationSuccess,
    meta: {
      public: true,
      title: 'Account Created',
      noIndex: true,
    },
  },
  {
    path: '/forgot',
    name: PageNames.Forgot,
    component: Forgot,
    meta: {
      public: true,
      title: 'Forgot Password',
      noIndex: true,
    },
  },
  {
    path: '/change-password',
    name: PageNames.ChangePassword,
    component: ChangePassword,
    meta: {
      public: true,
      title: 'Change Password',
      noIndex: true,
    },
  },
  {
    path: '/welcome',
    name: PageNames.Welcome,
    component: WelcomePage,
    meta: {
      title: 'Welcome',
    },
  },
  {
    path: '/',
    name: PageNames.Home,
    component: DefaultLayout,
    meta: {
      title: 'Home',
    },
    children: [
      {
        path: 'account',
        name: PageNames.Account,
        component: Account,
        meta: {
          title: 'Account',
        },
      },
      {
        path: 'videos/:viewId?',
        name: PageNames.Videos,
        component: VideoDirectory,
        meta: {
          hubOnly: true,
          title: 'Videos',
        },
      },
      {
        path: '/videos/:viewId?/new',
        name: PageNames.VideoNew,
        component: NewVideo,
        meta: {
          hubOnly: true,
          title: 'New Video',
        },
      },
      {
        path: 'views',
        name: PageNames.Views,
        component: Views,
        meta: {
          title: 'Views',
        },
      },
      {
        path: 'views/map/:viewId?',
        name: PageNames.ViewMap,
        component: ViewMap,
        meta: {
          title: 'Map View',
        },
      },
      {
        path: 'views/wallboard',
        name: PageNames.ViewWallboard,
        component: ViewWallboard,
        meta: {
          title: 'Wallboard',
          layout: 'fullscreen',
        },
      },
      {
        path: 'views/:id',
        name: PageNames.View,
        component: View,
        meta: {
          title: 'View',
        },
        children: [
          {
            path: 'gallery',
            name: PageNames.ViewGallery,
            component: ViewGallery,
            meta: {
              title: 'View Gallery',
            },
          },
          {
            path: 'videos',
            name: PageNames.ViewVideos,
            component: ViewVideos,
            meta: {
              hubOnly: true,
              title: 'View Videos',
            },
          },
          {
            path: 'downloads',
            name: PageNames.ViewDownloads,
            component: ViewDownloads,
            meta: {
              hubOnly: true,
              title: 'View Downloads',
            },
          },
          {
            path: '',
            name: PageNames.ViewViewer,
            component: ViewViewer,
            meta: {
              title: 'View Viewer',
            },
          },
        ],
      },
      {
        path: 'views/public/:id',
        name: PageNames.ViewPublic,
        component: PublicViewViewer,
        beforeEnter: checkPublicView,
        meta: {
          title: 'View Viewer',
          noIndex: true,
        },
      },
      {
        path: 'admin/events',
        name: PageNames.Events,
        component: Events,
        meta: {
          hubOnly: true,
          title: 'Events',
        },
      },
      {
        path: 'admin/users',
        name: PageNames.Users,
        component: UsersDirectory,
        meta: {
          hubOnly: true,
          title: 'Users',
        },
      },
      {
        path: 'admin/users/invite',
        name: PageNames.UserInvitation,
        component: UserInvitation,
        meta: {
          hubOnly: true,
          title: 'Invite User',
        },
      },
      {
        path: 'admin/users/:id',
        name: PageNames.User,
        component: User,
        meta: {
          hubOnly: true,
          title: 'Users',
        },
        children: [
          {
            path: 'edit',
            name: PageNames.UserEdit,
            component: UserEdit,
            meta: {
              hubOnly: true,
              title: 'Edit User',
            },
          },
          {
            path: 'roles/manage',
            name: PageNames.UserManageRoles,
            component: ManageUserRoles,
            meta: {
              hubOnly: true,
              title: 'Manage User Roles',
            },
          },
        ],
      },
      {
        path: 'admin/organisations',
        name: PageNames.Organisations,
        component: OrganisationsDirectory,
        meta: {
          hubOnly: true,
          title: 'Organisations',
        },
      },
      {
        path: 'admin/organisations/:id',
        name: PageNames.Organisation,
        component: Organisation,
        meta: {
          hubOnly: true,
          title: 'Organisation',
        },
        children: [
          {
            path: 'edit',
            name: PageNames.OrganisationSettings,
            component: OrganisationSettings,
            meta: {
              title: 'Edit Organisation',
            },
          },
        ],
      },
      {
        path: 'admin/cameras',
        name: PageNames.Cameras,
        component: Cameras,
        meta: {
          hubOnly: true,
          title: 'Cameras',
        },
      },
      {
        path: 'admin/cameras/unregistered',
        name: PageNames.CameraUnregistered,
        component: CameraUnregistered,
        meta: {
          hubOnly: true,
          title: 'Unregistered Cameras',
        },
      },
      {
        path: 'admin/cameras/register',
        name: PageNames.CameraRegister,
        component: CameraRegister,
        meta: {
          hubOnly: true,
          title: 'Register Camera',
        },
      },
      {
        path: 'admin/cameras/create',
        name: PageNames.CameraNew,
        component: CameraCreate,
        meta: {
          hubOnly: true,
          title: 'Create Camera',
        },
      },
      {
        path: 'admin/cameras/:id/edit',
        name: PageNames.CameraEdit,
        component: CameraEdit,
        meta: {
          hubOnly: true,
          title: 'Edit Camera',
        },
      },
      {
        path: 'admin/cameras/provision/:code?',
        name: PageNames.CameraProvision,
        component: CameraProvision,
        meta: {
          hubOnly: true,
          title: 'Provision Camera',
        },
      },
      {
        path: 'admin/cameras/:id',
        name: PageNames.Camera,
        component: Camera,
        meta: {
          hubOnly: true,
          title: 'Camera',
        },
        children: [
          {
            path: '',
            name: PageNames.CameraStatus,
            component: CameraStatus,
            meta: {
              title: 'Status',
            },
          },
          {
            path: 'status',
            name: PageNames.CameraStatus,
            component: CameraStatus,
            meta: {
              title: 'Status',
            },
          },
          {
            path: 'settings',
            name: PageNames.CameraSettings,
            component: CameraSettings,
            meta: {
              title: 'Camera Settings',
            },
          },
          {
            path: 'power',
            name: PageNames.CameraPower,
            component: CameraPower,
            meta: {
              title: 'Power',
            },
          },
          {
            path: 'logs',
            name: PageNames.CameraLogs,
            component: CameraLogs,
            meta: {
              title: 'Logs',
            },
          },
          {
            path: 'network',
            name: PageNames.CameraNetwork,
            component: CameraNetwork,
            meta: {
              title: 'Network',
            },
          },
          {
            path: 'schedule',
            name: PageNames.CameraSchedule,
            component: CameraSchedule,
            meta: {
              title: 'Schedule',
            },
          },
          {
            path: 'api',
            name: PageNames.CameraAPI,
            component: CameraApi,
            meta: {
              title: 'API',
            },
          },
        ],
      },
      {
        path: 'admin/clients',
        name: PageNames.Clients,
        component: AdminClients,
        meta: {
          hubOnly: true,
          title: 'Clients',
        },
      },
      {
        path: 'admin/clients/new',
        name: PageNames.ClientCreate,
        component: AdminClientCreation,
        meta: {
          hubOnly: true,
          title: 'Create Client',
        },
      },
      {
        path: 'admin/client/:id/edit',
        name: PageNames.ClientSettings,
        component: AdminClientSettings,
        meta: {
          hubOnly: true,
          title: 'Edit Client',
        },
      },
      {
        path: 'admin/client/:id/projects/new',
        name: PageNames.ClientProjectsCreate,
        component: AdminProjectCreation,
        meta: {
          hubOnly: true,
          title: 'Create Project',
        },
      },
      {
        path: 'admin/views',
        name: PageNames.AdminViews,
        component: AdminViews,
        meta: {
          hubOnly: true,
          title: 'Admin Views',
        },
      },
      {
        path: 'admin/views/new',
        name: PageNames.ViewNew,
        component: ViewNew,
        meta: {
          hubOnly: true,
          title: 'New View',
        },
      },
      {
        path: 'admin/views/:id/edit',
        name: PageNames.ViewEdit,
        component: ViewEdit,
        meta: {
          hubOnly: true,
          title: 'Edit View',
        },
      },
      {
          path: 'admin/views/:id/preview',
          name: PageNames.ViewPreview,
          component: ViewPreview,
          meta: {
              hubOnly: true,
              title: 'View Preview',
          },
      },
      {
          path: 'admin/views/:id/transfers',
          name: PageNames.ViewTransfers,
          component: ViewTransfers,
          meta: {
              hubOnly: true,
              title: 'View Transfers',
          },
      },
      {
          path: 'admin/views/:id/transfers/new',
          name: PageNames.ViewTransfersCreate,
          component: ViewTransfersCreate,
          meta: {
              hubOnly: true,
              title: 'Create Transfer',
          },
      },
      {
        path: 'admin/projects',
        name: PageNames.AdminProjects,
        component: AdminProjects,
        meta: {
          hubOnly: true,
          title: 'Admin Projects',
        },
      },
      {
        path: 'admin/projects',
        name: PageNames.Projects,
        component: AdminProjects,
        meta: {
          hubOnly: true,
          title: 'Projects',
        },
      },
      {
        path: 'admin/projects/:id/edit',
        name: PageNames.ProjectEdit,
        component: AdminProjectSettings,
        meta: {
          hubOnly: true,
          title: 'Edit Project',
        },
      },
      {
        path: 'admin/projects/new',
        name: PageNames.ProjectCreate,
        component: AdminProjectCreation,
        meta: {
          hubOnly: true,
          title: 'Create Project',
        },
      },
      {
          path: 'admin/clients',
          name: PageNames.AdminClients,
          component: AdminClients,
          meta: {
              hubOnly: true,
              title: 'Admin Clients',
          },
      },
      {
          path: 'admin/transfers',
          name: PageNames.AdminTransfers,
          component: AdminTransfers,
          meta: {
              hubOnly: true,
              title: 'Admin Transfers',
          },
      },
      {
          path: 'admin/transfers/new',
          name: PageNames.TransferSettingCreate,
          component: AdminTransferCreation,
          meta: {
              hubOnly: true,
              title: 'Create Transfer',
          },
      },
      {
          path: 'admin/transfers/:id/edit',
          name: PageNames.TransferSettings,
          component: AdminTransferSettings,
          meta: {
              hubOnly: true,
              title: 'Edit Client',
          },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: PageNames.NotFound,
    component: ErrorLayout,
    meta: {
      public: true,
      title: 'Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // If adding any scrollTo behaviours check for conflicts with the /views/map/{viewId} (Google Map) page as that page uses scrollTo events
});

router.beforeEach(authenticationRequired);
router.beforeEach(organisationRequired);
router.beforeEach(hubOnly);

/**
 * Updates the document title and noindex meta tag after each route change.
 */
const head = document.head;
let noIndexMetaTag = head.querySelector('meta[name="robots"]') as HTMLMetaElement | null;

router.afterEach((to) => {
    // Use the meta.title property if it exists
    document.title = to.meta?.['title'] ? `gabrielCAM | ${to.meta['title']}` : '';

    // If the route does NOT have 'noIndex', remove any existing meta tag
    if (!to.meta?.['noIndex']) {
        if (noIndexMetaTag) {
            noIndexMetaTag.remove();
            noIndexMetaTag = null;
        }
    } else {
        // If 'noIndex' is true, add or update the meta tag
        if (!noIndexMetaTag) {
            noIndexMetaTag = document.createElement('meta');
            noIndexMetaTag.name = 'robots';
            head.prepend(noIndexMetaTag);
        }
        noIndexMetaTag.content = 'noindex, nofollow';
    }
});

export default router;
