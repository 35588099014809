<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useApplicationStore } from '@stores/application';
import * as client from '@gabrielcam/api-client';
import TransferSettingForm, { TransferSettingFormInterface } from '@components/transfer/TransferSettingForm.vue';
import { BreadcrumbPaths, BreadcrumbTitles, PageNames } from '@viewModels/enums';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import SubHeader from '@components/SubHeader.vue';
import { extractErrorMessage } from '@utils/errorUtils';

// Setup
const router = useRouter();
const route = useRoute();
const applicationStore = useApplicationStore();

// Loading
const isLoading = ref<boolean>(true);
const isSubmitting = ref<boolean>(false);

// Form
const transferSetting = ref<TransferSettingFormInterface>();
const transferSettingId = route.params['id'] as string;

// Breadcrumbs
const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllClients, to: BreadcrumbPaths.ViewTransfers },
  { title: BreadcrumbTitles.UpdateTransfer, active: true },
];

// Submit Form
const onSubmit = async (values: TransferSettingFormInterface): Promise<void> => {
  if (!applicationStore.activeOrganisation || !transferSettingId) return;

  isSubmitting.value = true;
  try {
    await client.updateTransferSettingById({
      transferSettingId,
      requestBody: {
        name: values.name,
        options: {
          server: values.server,
          username: values.username,
          password: values.password,
          type: values.type,
          port: values.port,
        }
      }
    });

    applicationStore.publishSuccessNotification({
      text: 'Successfully created client.',
      autoCloseMs: 3000,
    });

  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    applicationStore.publishErrorNotification({ text: errorMessage });
  } finally {
    isSubmitting.value = false;
  }

  await router.push({ name: PageNames.AdminTransfers });
};

const onCancel = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.ViewTransfers as string);
  } else if (routerHistory.state['back']) {
    router.go(-1);
  }
};

onMounted(async (): Promise<void> => {
  isLoading.value = true;
  const response = await client.getTransferSettingById({ transferSettingId })
  const options = response.options as TransferSettingFormInterface;
  transferSetting.value = {
    name: response.name,
    server: options.server,
    username: options.username,
    password: options.password,
    type: options.type,
    path: options.path,
    port: options.port,
  }
  isLoading.value = false;
})
</script>

<template>
  <SubHeader heading="Update Transfer"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <TransferSettingForm v-if="!isLoading"
                       :is-submitting="isSubmitting"
                       :data="transferSetting"
                       @on-submit="(values) => onSubmit(values)"
                       @on-cancel="onCancel" />
</template>
