<script setup lang="ts">
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';
import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import AssignRoleForm from '@components/user/AssignRoleForm.vue';
import AssignedRoles from '@components/user/AssignedRoles.vue';
import { computed, onMounted, Ref, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import { useApplicationStore } from '@stores/application';
import HorizontalRule from '@components/HorizontalRule.vue';

const user = ref({}) as Ref<client.User>;
const projects = ref<client.Project[]>([]);
const views = ref<client.View[]>([]);
const roles = ref<client.Role[]>([]);
const route = useRoute();
const userId: string = route.params['id'] as string;
const isLoadingUser = ref<boolean>(true);
const isLoadingResources = ref<boolean>(true);
const breadcrumbs = computed<BreadCrumbItem[]>(() => [
  { title: BreadcrumbTitles.AllUsers, to: BreadcrumbPaths.AllUsers },
  { title: `${user.value.displayName}`, to: BreadcrumbPaths.AllUsers },
  { title: BreadcrumbTitles.ManagerRoles, active: true },
]);
const applicationStore = useApplicationStore();

const loadUser = async (): Promise<void> => {
  user.value = await client.getUserById({
    organisation: applicationStore.activeOrganisation!.id,
    userId
  });
  isLoadingUser.value = false;
}

const loadResources = async (): Promise<void> => {
  isLoadingResources.value = true;
  projects.value = (await client.listProjects({ organisation: applicationStore.activeOrganisation!.id })).data
  views.value = (await client.listViews({ organisation: applicationStore.activeOrganisation!.id })).data;
  roles.value = (await client.listRoles()).data.filter((role) => role.userAssignable);
  isLoadingResources.value = false;
}

onMounted(async () => {
  await loadUser();
  await loadResources();
});
</script>

<template v-if="!isLoadingUser">
  <SubHeader heading="Manage Roles"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <AssignRoleForm :user="user"
                    :projects="projects"
                    :views="views"
                    :roles="roles"
                    :is-loading="isLoadingUser || isLoadingResources"
                    @roles-updated="loadUser()" />

    <HorizontalRule />

    <AssignedRoles :user="user"
                   :projects="projects"
                   :views="views"
                   :roles="roles"
                   :is-loading="isLoadingUser || isLoadingResources"
                   @roles-updated="loadUser()" />
  </ContainerCard>
</template>
