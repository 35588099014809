<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCurrentRoute } from '@utils/getCurrentRoute';
import { useViewStore, View } from '@stores/view';
import * as client from '@gabrielcam/api-client';
import SubHeader from '@components/SubHeader.vue';
import Loading from '@components/Loading.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';
import { useApplicationStore } from '@stores/application';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import EmptyState from '@layouts/EmptyState.vue';

// Stores
const applicationStore = useApplicationStore();
const viewStore = useViewStore();
const route = useRoute();
const { currentRouteMetaTitle } = useCurrentRoute();

// Views
const viewId = route.params['id'] as string;
const currentView = ref<View>();
const currentViewName = ref<string>();
const currentCameraId = ref<string>();

// Loading
const isLoading = ref<boolean>(true);
const viewIsRegistered = ref<boolean>(true);

// Breadcrumb Data
const breadcrumbKey = ref(0);
const breadcrumbs = ref<BreadCrumbItem[]>([]);

// Heading
const pageHeading = computed(() => {
  return currentViewName.value + ' ' + currentRouteMetaTitle.value;
});

function updateBreadcrumbs(): void {
  if (currentView.value?.name) {
    breadcrumbs.value = [
      { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews  },
      { title: `${currentView.value.name} `, active: false },
      { title: `${currentRouteMetaTitle.value}`, active: true },
    ];
    breadcrumbKey.value += 1; // HACK: Increment key to force re-render
  } else {
    breadcrumbs.value = [];
  }
}


const fetchViewData = async (): Promise<void> => {
  try {
    // Fetch the current view data using route param
    currentView.value = await viewStore.getViewById(viewId);
    // Update used values
    currentViewName.value = currentView.value.name;
    currentCameraId.value = currentView.value.camera;

    // Fetch the registered cameras from the org
    const cameraResponse = await client.listCameras({ organisation: applicationStore.activeOrganisation!.id });
    const registeredCameraMap = new Map(
      cameraResponse.data
        .filter((camera: client.Camera) => camera.status === client.CameraStatus.REGISTERED)
        .map((camera: client.Camera) => [camera.id, true])
    );

    // Check if the current view's camera is registered on the view
    viewIsRegistered.value = registeredCameraMap.has(currentView.value.camera!);
  } catch (error) {
    console.error("Error fetching view data:", error);
    // Set viewIsRegistered to false if there is an error
    viewIsRegistered.value = false;
  }
};

// Lifecycle
onMounted(async () => {
  isLoading.value = true;
  await fetchViewData();
  updateBreadcrumbs();
  isLoading.value = false;
});
</script>

<template>
  <SubHeader :heading="isLoading ? 'Loading...' : pageHeading"
             level="2" />

  <Breadcrumb :key="breadcrumbKey"
              :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Loading v-if="isLoading" />
    <template v-else>
      <template v-if="viewIsRegistered">
        <p>{{ currentView }}</p>

        <h4>Content here</h4>
        <p>View name: {{ currentViewName }}</p>
        <p>Camera ID: {{ currentCameraId }}</p>
        <p>View is registered: {{ viewIsRegistered }}</p>
      </template>

      <template v-else>
        <EmptyState heading-text="View is not registered"
                    :icon-name="IconName.PhotoIcon"
                    :icon-style="IconStyle.Outline" />
      </template>
    </template>
  </ContainerCard>
</template>
