<script setup lang="ts">
import { computed, ref } from 'vue';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import Pagination from '@components/Pagination.vue';

const props = defineProps<{
  total: number,
  page: number,
  limit: number
}>();

defineEmits<{
  (e: 'pageChanged', page: number): void;
  (e: 'limitChanged', limit: number): void;
}>();

// Pagination Setup
const perPage = ref({ value: props.limit, text: `${props.limit} per page` });

// Pagination Buttons
const isFirstPage = computed(() => props.page === 1);
const isLastPage = computed(() => props.page === Math.ceil(props.total / props.limit));
const hasPreviousPage = computed(() => props.page > 1);
const hasNextPage = computed(() => props.page < Math.ceil(props.total / props.limit));


// Define the options for the per page dropdown
const perPageOptions = [
  { value: 3, text: '3 per page' },
  { value: 6, text: '6 per page' },
  { value: 9, text: '9 per page' },
  { value: 12, text: '12 per page' },
  { value: 24, text: '24 per page' },
  { value: 36, text: '36 per page' },
  { value: 48, text: '48 per page' },
  { value: 60, text: '60 per page' },
];
</script>

<template>
  <Pagination>
    <template #pagination-dropdown>
      <v-select v-model="perPage"
                input-id="per-page"
                aria-label="Select number per page"
                label="text"
                :options="perPageOptions"
                :clearable="false"
                :searchable="false"
                :reduce="(value: any) => value.value"
                @option:selected="(e: any) => $emit('limitChanged', e.value)" />
    </template>

    <template v-if="props.total > 0 && (!isFirstPage || !isLastPage)" #pagination-buttons>
      <!-- First Page Button -->
      <ButtonComponent :disabled="isFirstPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronDoubleLeftIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', 1)" />

      <!-- Previous Page Button -->
      <ButtonComponent :disabled="!hasPreviousPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronLeftIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', props.page - 1)" />

      <!-- Next Page Button -->
      <ButtonComponent :disabled="!hasNextPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Right"
                       :icon-name="IconName.ChevronRightIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', props.page + 1)" />

      <!-- Last Page Button -->
      <ButtonComponent :disabled="isLastPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Right"
                       :icon-name="IconName.ChevronDoubleRightIcon"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', Math.ceil(props.total / props.limit))" />
    </template>

    <template #pagination-info>
      <p v-if="props.total > 0">
        {{ ((props.page - 1) * props.limit) + 1 }} to
        {{ Math.min(props.total, ((props.page - 1) * props.limit) + props.limit) }}
        of {{ props.total }} results
      </p>
      <p v-else>
        No results found
      </p>
    </template>
  </Pagination>
</template>


