<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const props = defineProps<{
  clientId: string | undefined;
}>();

interface ProjectCreationForm {
  name: string;
  client: string;
}

const schema = yup.object({
  name: yup.string().required(),
  client: yup.string().required(),
});

const { handleSubmit } = useForm<ProjectCreationForm>({
  validationSchema: schema,
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: clientValue, errorMessage: clientError } = useField<string>('client', 'client');

const router = useRouter();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);

let filteredClients: client.Client[] = [];
if (props.clientId) {
  const clientResult = await client.getClientById({ clientId: props.clientId });
  filteredClients = [clientResult];
} else {
  const clients = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
  filteredClients = [...clients.data];
}

if (filteredClients.length === 1) {
  clientValue.value = filteredClients.at(0)!.id;
}

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  if (applicationStore.activeOrganisation == undefined) return;
  const requestBody: client.CreateProjectRequest = {
    name: values.name,
    client: values.client,
    organisation: applicationStore.activeOrganisation.id,
  };
  try {
    await client.createProject({ requestBody });
  } catch (error) {
    console.error(error);
    applicationStore.publishErrorNotification({ text: 'Error creating project' });
    isSubmitting.value = false;
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully created project.',
    autoCloseMs: 3000,
  });
  if (props.clientId) {
    router.push({ name: PageNames.ClientSettings, params: { id: props.clientId } });
  } else {
    router.push({ name: PageNames.Projects });
  }
});

// Cancel Button
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /projects
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Projects as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Project Information
        </Heading>
        <p>Add a new project.</p>
      </div>
      <div class="fields">
        <div class="field">
          <label for="project-name">Name</label>
          <input id="project-name"
                 v-model="nameValue"
                 type="text">
          <p class="message message-error">
            {{ nameError }}
          </p>
        </div>
        <div class="field">
          <label for="model">Client</label>
          <v-select v-model="clientValue"
                    label="name"
                    :options="filteredClients"
                    :reduce="(client: client.Client) => client.id"
                    @search="
                      (search: string) => {
                        filteredClients = filteredClients.filter((x) => {
                          if (!!search.length) return true;
                          return x.name.toLowerCase().includes(search.toLowerCase());
                        });
                      }
                    " />
          <p class="message message-error">
            {{ clientError }}
          </p>
        </div>
      </div>
    </div>

    <ButtonContainer>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :disabled="isSubmitting">
        Create
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
