<script setup lang="ts">
import { Ref, ref } from 'vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const props = defineProps<{
  signIn: Function;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
  (e: 'onBack'): void;
  (e: 'update:email', value: String): void;
  (e: 'update:password', value: String): void;
}>();

const isSubmitting: Ref<boolean> = ref(false);
const errorMessage: Ref<string> = ref('');

interface LoginWithEmailAndPasswordForm {
  email: string;
  password: string;
}

const schema = yup.object({
  email: yup
    .string()
    .email()
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required'),
});

const { handleSubmit } = useForm<LoginWithEmailAndPasswordForm>({
  validationSchema: schema,
});

const { value: emailValue, errorMessage: emailError } = useField<string>('email', 'email', {
  validateOnValueUpdate: false,
  modelPropName: 'email',
});

const { value: passwordValue, errorMessage: passwordError } = useField<string>('password', 'password', {
  modelPropName: 'password',
});

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;

  try {
    await props.signIn(values.email, values.password);
    emit('onSuccess');
  } catch (error) {
    errorMessage.value = 'Please check your details and try again.';
    console.error('Login error:', error);
    return;
  } finally {
    isSubmitting.value = false;
  }
});
</script>

<template>
  <form @submit="onSubmit">
    <section class="account-form--content">
      <div class="fields">
        <div class="field">
          <label for="email-address">Email address</label>
          <input id="email-address"
                 v-model="emailValue"
                 type="email"
                 autocomplete="email">
          <p class="message message-error">
            {{ emailError }}
          </p>
        </div>
        <div class="field">
          <label for="password">Password</label>
          <input id="password"
                 v-model="passwordValue"
                 type="password"
                 autocomplete="current-password">
          <p class="message message-error">
            {{ passwordError }}
          </p>
        </div>
        <p v-if="errorMessage" class="message message-error" style="border-radius: unset;">
          {{ errorMessage }}
        </p>
      </div>
    </section>

    <ButtonContainer>
      <ButtonComponent :disabled="isSubmitting"
                       :loading="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Login
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
