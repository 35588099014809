<template>
  <main class="public-viewer--container">
    <slot name="router-view" />
  </main>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.public-viewer {
  &--container {
    padding: 30px clamp($gap-mobile, 3vw, $gap-desktop) 0;
    background-image: linear-gradient(170deg, var(--secondary-color, $secondary-color, 0.5) 20%, transparent 100%);
    min-height: calc(100vh - 135px); // 80px is the height of the header + 55px for sub header

    @media screen and (width >= $breakpoint-lg) {
      min-height: calc(100vh - 80px); // 80px is the height of the header
    }
  }
}
</style>
