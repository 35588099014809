<script lang="ts" setup>
import { onMounted, Ref, ref, watch } from 'vue';
import { useApplicationStore } from '@stores/application';
import { dateTimeFormat } from '@utils/date';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useVideoStore, Video } from '@stores/video';
import * as client from '@gabrielcam/api-client';
import { BreadcrumbTitles, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Directory from '@components/directory/Directory.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import VideoDirectoryToolbar from '@components/video/VideoDirectoryToolbar.vue';
import VideoDirectoryPagination from '@components/video/VideoDirectoryPagination.vue';
import VideoPlayer from '@components/video/VideoPlayer.vue';
import VideoCard from '@components/cards/VideoCard.vue';
import EmptyState from '@layouts/EmptyState.vue';
import HorizontalRule from '@components/HorizontalRule.vue';
import { Entitlements } from '@gabrielcam/api-client';

// Stores
const videoStore = useVideoStore();
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

// Consts
const route = useRoute();
const { videos } = storeToRefs(videoStore);

// Loading States
const isLoading = ref<boolean>(true);

// Permissions
const showNew = applicationStore.canUser(client.Entitlements.CREATE_SEQUENCE, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(Entitlements.DELETE_SEQUENCE, applicationStore.activeOrganisation!);


const selectedFilters = ref({
  client: undefined,
}) as Ref<{ client: string | undefined }>;

const viewId = ref<string>();
const sortBy = ref<string | undefined>();

// Video Player Modal
const videoPlayerVisible = ref(false);
const videoPlayerUrl = ref<string>('');
const videoSequenceId = ref<string>();
const videoCreatedAt = ref<string>();

// Delete Video Modal
const deleteVideoVisible = ref(false);
const videoToDelete = ref<string>();

watch(
  () => route.params['viewId'],
  async (viewId) => {
    await videoStore.getVideos(viewId as string, sortBy.value);
  },
);

async function updateSort(sortBy: string, sortOrder: string): Promise<void> {
  const sortValue = `${sortOrder}${sortBy}`;
  // Fetch sorted videos
  await videoStore.getVideos(viewId.value, sortValue);

  // Log to check if the sorted data is being returned
  // console.log('Updated videos data:', videos.value);
}

onMounted(async () => {
  isLoading.value = true;
  viewId.value = route.params['viewId'] as string | undefined;

  videoStore.setPagination();
  await videoStore.getVideos(viewId.value);
  isLoading.value = false;
});


// Open Video Player Modal
function openVideoPlayer(video: Video): void {
  videoPlayerVisible.value = true;
  videoPlayerUrl.value = video.url ?? '';
}

// Close Video Player Modal
function closeVideoPlayerModal(): void {
  videoPlayerVisible.value = false;
}

// Open Delete Video Modal
function openDeleteVideoModal(video: Video): void {
  deleteVideoVisible.value = true;
  // Pass the video details to the modal
  videoToDelete.value = video.viewName ?? 'Unknown';
  videoSequenceId.value = video.id;
  videoCreatedAt.value = dateTimeFormat(activeUser?.timezone).format(new Date(video.createdAt));
}

// Close Delete Video Modal
function closeDeleteVideoModal(): void {
  deleteVideoVisible.value = false;
}

// Delete Video
async function handleDeleteVideo(): Promise<void> {
  try {
    await videoStore.deleteVideo({ sequenceId: videoSequenceId.value as string });
    videoSequenceId.value = ''; // Clear the videoSequenceId
  } catch (error) {
    console.error('Error in handleDeleteVideo:', error);
    // Error notifications are already handled in the store
  } finally {
    closeDeleteVideoModal();
  }
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.Videos, active: true },
];
</script>

<template>
  <SubHeader heading="Videos"
             level="2">
    <template v-if="showNew"
              #buttons>
      <ButtonComponent :is-block-btn="true"
                       :to="{ name: PageNames.VideoNew, params: { viewId: viewId } }"
                       :variant="ButtonVariant.Dark"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add Video
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <div>
          <VideoDirectoryToolbar @select-client="(clientId: string) => (selectedFilters.client = clientId)"
                                 @sort-view-by="updateSort" />

          <Directory :object-collection-reference="videos.data"
                     :loading="isLoading">
            <template #default="scope">
              <VideoCard :resource="scope.resource"
                         @play-video="openVideoPlayer(scope.resource)"
                         @delete-video="openDeleteVideoModal(scope.resource)" />
            </template>
            <template #table-empty>
              <template v-if="showNew">
                <EmptyState heading-text="No videos found"
                            strap-line="Get started by creating a new video"
                            :button-variant="ButtonVariant.Dark"
                            button-text="New Video"
                            :icon-name="IconName.VideoCameraIcon"
                            :icon-style="IconStyle.Outline"
                            :to="{ name: PageNames.VideoNew }" />
              </template>
              <template v-else>
                <EmptyState heading-text="No videos found"
                            :icon-name="IconName.VideoCameraIcon"
                            :icon-style="IconStyle.Outline" />
              </template>
            </template>
          </Directory>

          <HorizontalRule />

          <VideoDirectoryPagination :view-id="viewId"
                                    @on-loading="isLoading = true"
                                    @on-loaded="isLoading = false" />
        </div>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Video Player Modal -->
  <ModalComponent :visible="videoPlayerVisible"
                  heading-title="Watch Video"
                  @on-close="closeVideoPlayerModal">
    <template #modal-content>
      <VideoPlayer :video-src="videoPlayerUrl" />
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeVideoPlayerModal">
        Close
      </ButtonComponent>
    </template>
  </ModalComponent>

  <!-- Delete Video Modal -->
  <ModalComponent :visible="deleteVideoVisible"
                  heading-title="Delete Video"
                  @on-close="closeDeleteVideoModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--semibold">{{ videoToDelete }}</span> video created at <span class="text--semibold">{{ videoCreatedAt }}</span>?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteVideoModal">
        Close
      </ButtonComponent>
      <ButtonComponent :disabled="!showDelete"
                       :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="handleDeleteVideo()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
