import { ref, computed, watch, ComputedRef } from 'vue';
import { useWindowSize, useDebounceFn } from '@vueuse/core';

const DEBOUNCE_DELAY = 500;
const IS_MOBILE_BREAKPOINT = 960; // Make sure you update the $breakpoint-lg in _variables.scss

/**
 * Provides a debounced reactive state for mobile detection based on window width.
 *
 * @returns {{ isMobile: ComputedRef<boolean> }} The reactive mobile detection state.
 */
export function useMobileDetection(): { isMobile: ComputedRef<boolean> } {
  const { width } = useWindowSize();
  const isMobile = ref(width.value <= 960);

  // Debounced update function
  const updateIsMobile = useDebounceFn(() => {
    isMobile.value = width.value <= IS_MOBILE_BREAKPOINT;
  }, DEBOUNCE_DELAY);

  // Watch for changes in width and trigger debounced update
  watch(width, () => {
    updateIsMobile().then(() => {
      console.log(`isMobile: ${isMobile.value}`);
    });
  });

  return {
    isMobile: computed(() => isMobile.value),
  };
}
