<script setup lang="ts">
import { useRoute } from 'vue-router';
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import ViewTransferForm from '@components/view/ViewTransferForm.vue';
import { onMounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';

// Stores
const route = useRoute();

// Views
const viewData = ref<client.View | null>(null);
const viewId = route.params['id'] as string;
const currentViewName = ref<string>();

// Loading
const isLoading = ref<boolean>(true);

// Breadcrumb Data
const breadcrumbs = ref<BreadCrumbItem[]>([]);

const fetchViewData = async (): Promise<void> => {
  try {
    const data = await client.getViewById({ viewId });
    if (!data) throw new Error('Invalid response from getViewById.');

    viewData.value = data;
    currentViewName.value = data.name;
  } catch (error) {
    console.error("Error fetching view data:", error);
  }
};

// Update the Breadcrumbs once we have the currentViewName
function updateBreadcrumbs(): void {
  if (currentViewName.value) {
    breadcrumbs.value = [
      { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews  },
      { title: BreadcrumbTitles.AllTransfers, to: BreadcrumbPaths.ViewTransfers.replace(':id', viewId) },
      { title: `${currentViewName.value} `, active: false },
      { title: BreadcrumbTitles.CreateTransfer, active: true },
    ];
  } else {
    breadcrumbs.value = [];
  }
}

onMounted(async () => {
  isLoading.value = true;
  await fetchViewData();
  updateBreadcrumbs();
  isLoading.value = false;
});
</script>

<template>
  <SubHeader :heading="isLoading ? 'Loading...' : currentViewName + ' ' + BreadcrumbTitles.CreateTransfer"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <ViewTransferForm :view-id="viewId" />
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
