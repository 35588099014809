<script setup>
import { ref } from 'vue';
import { useFullscreen } from '@vueuse/core';

// Fullscreen target
const fullscreenElement = ref(null);

// UseFullscreen API
const { isFullscreen, toggle, isSupported } = useFullscreen(fullscreenElement);
</script>

<template>
  <div ref="fullscreenElement" class="fullscreen-wrapper" :class="{ 'fullscreen-active': isFullscreen }">
    <slot :is-fullscreen="isFullscreen" :toggle="toggle" :is-supported="isSupported" />
  </div>
</template>

<style scoped lang="scss">
@use '@scss/variables' as *;

.fullscreen-wrapper {
  width: 100%;
  height: 100%;

  &.fullscreen-active {
    background-color: $neutral-50;
    overflow-y: auto;
  }
}
</style>
