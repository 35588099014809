<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import SubHeader from '@components/SubHeader.vue';
import Loading from '@components/Loading.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonSize, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import TableComponent from '@components/table/TableComponent.vue';
import EmptyState from '@layouts/EmptyState.vue';
import { useApplicationStore } from '@stores/application';
import ModalComponent from '@components/ModalComponent.vue';

const enum TableHeaders {
  Name = 'Name',
  Path = 'Path',
  Delete = 'Delete',
}

// Reload the table
interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

// Stores
const applicationStore = useApplicationStore();
const route = useRoute();

// Permissions
const showCreate = applicationStore.canUser(client.Entitlements.CREATE_VIEW, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.UPDATE_VIEW, applicationStore.activeOrganisation!);

// Views
const viewData = ref<client.View | null>(null);
const viewId = route.params['id'] as string;
const currentViewName = ref<string>();

// Loading
const isLoading = ref<boolean>(true);
const isDeleting = ref(false);

// Delete Modal
const deleteTransferModelFunc = ref<Function>(() => deleteTransfer);
const showDeleteTransferModal = ref(false);
const transferName = ref<string>();

// Breadcrumb Data
const breadcrumbs = ref<BreadCrumbItem[]>([]);

// Heading
const pageHeading = computed(() => {
  return currentViewName.value + ' ' + BreadcrumbTitles.ViewTransfers;
});

function updateBreadcrumbs(): void {
  if (currentViewName.value) {
    breadcrumbs.value = [
      { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews  },
      { title: `${currentViewName.value} `, active: false },
      { title: BreadcrumbTitles.ViewTransfers, active: true },
    ];
  } else {
    breadcrumbs.value = [];
  }
}

// Fetch and store the view data once
const fetchViewData = async (): Promise<void> => {
  try {
    viewData.value = await client.getViewById({
      viewId: viewId,
      expand: [client.Resources_TransferSettings.TRANSFER_SETTINGS],
    });

    // Get the view name for breadcrumbs
    currentViewName.value = viewData.value.name;

    if (!viewData.value) throw new Error('Invalid response from getViewById.');

  } catch (error) {
    console.error("Error fetching view data:", error);
  }
};

const deleteTransfer = async (transferSetting: string, path: string): Promise<void> => {
  isDeleting.value = true;
  try {
    await client.updateViewByIdDeleteTransfer({
      viewId: viewId,
      requestBody: { transferSetting, path },
    });

    applicationStore.publishSuccessNotification({
      text: 'Successfully deleted transfer.',
      autoCloseMs: 3000,
    });

    // Refresh data after deletion
    await fetchViewData();
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    } else {
      applicationStore.publishErrorNotification({ text: 'Unknown error deleting transfer' });
    }
  } finally {
    isDeleting.value = false;
    await closeDeleteConfirmModal()
  }
};

onMounted(async () => {
  isLoading.value = true;
  await fetchViewData();
  updateBreadcrumbs();
  isLoading.value = false;
});

// Table
const tableRef = ref<DirectoryTableType | null>(null);
const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.Name,
    dataField: 'name',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '48%',
    isSortable: true,

  },
  {
    labelText: TableHeaders.Path,
    dataField: 'path',
    columnWidth: '48%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Delete,
    dataField: 'delete',
    columnWidth: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'name',
    sort: 'asc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

// Delete Modal
async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteTransferModal.value = false;
  isDeleting.value = false;
}

async function showDeleteConfirmModal(row: client.ViewTransferSetting): Promise<void> {
  showDeleteTransferModal.value = true;
  transferName.value = row.name;

  // Extract transferSetting and path from the row
  const transferSetting = row.transferSetting;
  const path = row.path;

  deleteTransferModelFunc.value = async (): Promise<void> => {
    await deleteTransfer(transferSetting, path);
  };
}
</script>

<template>
  <SubHeader :heading="isLoading ? 'Loading...' : pageHeading"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="showCreate"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ViewTransfersCreate, params: { id: viewId } }"
                       :variant="ButtonVariant.Dark"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Create Transfer
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <Loading v-if="isLoading" />
          <TableComponent v-else
                          ref="tableRef"
                          :columns="table.columns"
                          :data="viewData?.transfers"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No transfers found"
                          :icon-name="IconName.ClipboardDocumentListIcon"
                          :icon-style="IconStyle.Outline" />
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.labelText === TableHeaders.Delete">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="showDelete"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Danger"
                                   @click="showDeleteConfirmModal(row)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </TableComponent>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete Transfer Modal -->
  <ModalComponent :visible="showDeleteTransferModal"
                  heading-title="Delete Transfer"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the transfer for <span class="text--semibold">{{ transferName }}</span>?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Button"
                       :is-block-btn="true"
                       :loading="isDeleting"
                       :disabled="isDeleting"
                       :variant="ButtonVariant.Danger"
                       @click="deleteTransferModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
