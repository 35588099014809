<script setup lang="ts">
import { ButtonVariant } from '@viewModels/enums';
import { computed } from 'vue';

// Interface
interface ButtonIconGroupProps {
  variant?: ButtonVariant.Primary | ButtonVariant.Secondary | ButtonVariant.Dark;
}

// Define Props
const props = defineProps<ButtonIconGroupProps>();

// Computed Props
const variant = computed(() => props.variant || ButtonVariant.Dark);
</script>

<template>
  <div :class="[
    'button-icon-group',
    `button-icon-group--${variant || ButtonVariant.Dark}`]">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use '@scss/variables' as *;

// Theme variables
$primary-color: $primary-color;
$secondary-color: $gold-600;
$dark-color: $neutral-300;

.button-icon-group {
  display: flex;
  border-radius: 5px;

  :deep(button) {
    border: none;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    &.btn--disabled {
      opacity: unset;
    }
  }

  &--primary {
    box-shadow: inset 0 0 0 1px $primary-color;

    :deep(button:not(:last-child)) {
      border-right: 1px solid $primary-color;
    }
  }

  &--secondary {
    box-shadow: inset 0 0 0 1px $secondary-color;

    :deep(button:not(:last-child)) {
      border-right: 1px solid $secondary-color;
    }
  }

  &--dark {
    box-shadow: inset 0 0 0 1px $dark-color;

    :deep(button:not(:last-child)) {
      border-right: 1px solid $dark-color;
    }
  }
}
</style>