<script setup lang="ts">
import { computed } from 'vue';

// Emits
const emits = defineEmits(['update:modelValue']);

// Types
type WidthSize = `${number}px` | `${number}%`;

// Props
const props = defineProps<{
  label: string;
  hideLabel?: boolean;
  inputId: string;
  modelValue: number;
  min?: number;
  max?: number;
  step?: number;
  prefix?: string;
  suffix?: string;
  format?: 'currency' | 'percentage' | 'number';
  maxWidth?: WidthSize; // allows to pass a number as % or px
}>();

// Default props
const min = props.min ?? 0;
const max = props.max ?? 100;
const step = props.step ?? 1;
const maxWidth = props.maxWidth ?? '100%';

/**
 * Dynamically calculates the minimum width of the range slider label based on the length of the maximum value.
 * This prevents the UI from jumping around when the user changes the value from 0 to 100.
 *
 * @returns {string} The minimum width in CSS ch units.
 */
const computedMinWidth = computed(() => {
  const maxText = `${props.prefix ?? ''}${props.max ?? 100}${props.suffix ?? ''}`;
  return `${maxText.length * 1.5}ch`;
});

/**
 * Formats the value based on the format prop.
 *
 * This function takes a number as input and returns a formatted string based on the format prop.
 * The format prop can be one of 'currency', 'percentage', or 'number'.
 * Returns the formatted value as a string ie '£100.00', '50%', or '10,000'.
 * @param value - The number to format.
 * @returns The formatted value as a string.
 */
const formatValue = (value: number): string => {
  const formats: Record<'currency' | 'percentage' | 'number', () => string> = {
    currency: () => `${props.prefix ?? ''}${value.toFixed(2)}${props.suffix ?? ''}`,
    percentage: () => `${value}${props.suffix ?? '%'}`,
    number: () => value.toLocaleString(),
  };

  // Safely access the format function, defaulting to 'number' if the format is not defined
  const formatFunction = formats[props.format ?? 'number'] || formats.number;

  return formatFunction();
};


/**
 * Updates the model value when the range slider input changes.
 *
 * @param event The input event triggered by the range slider.
 */
const updateModelValue = (event: Event): void => {
  const target = event.target as HTMLInputElement;
  const value = Number(target.value); // Convert string to number
  emits('update:modelValue', value);
};
</script>

<template>
  <div class="range-slider" :style="{ maxWidth: maxWidth }">
    <label v-if="!hideLabel"
           :for="inputId"
           class="range-slider__label">
      {{ label }}:
      <span class="range-slider__label--value"
            :style="{ minWidth: computedMinWidth }">
        {{ formatValue(modelValue) }}
      </span>
    </label>

    <div class="range-slider__container">
      <span class="range-slider__min">{{ formatValue(min) }}</span>
      <input :id="inputId"
             :value="modelValue"
             class="range-slider__input"
             :style="{ background: `linear-gradient(to right, var(--primary-color) ${modelValue}%, #d4d4d4 ${modelValue}%)` }"
             type="range"
             :min="min"
             :max="max"
             :step="step"
             v-bind="hideLabel ? { 'aria-label': label } : {}"
             @input="updateModelValue">
      <span class="range-slider__max">{{ formatValue(max) }}</span>
    </div>
  </div>
</template>


<style lang="scss" scoped>
@use '@scss/variables' as *;

.range-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0 20px;
  margin: auto;

  &__label {
    display: inline-flex;
    gap: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
  }

  &__min,
  &__max {
    font-size: 0.875rem;
    color: inherit;
    text-align: center;
  }

  &__input {
    flex: 1;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 15px;
    outline: none;
    background: transparent;
    cursor: pointer;

    &::-webkit-slider-runnable-track,
    &::-moz-range-track {
      height: 8px;
      background: transparent;
      border-radius: 15px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 20px;
      width: 20px;
      background: var(--secondary-color, $secondary-color);
      border-radius: 50%;
      border: none;
      cursor: pointer;
      transition: background 0.2s ease-in-out;

      &:hover {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
      }

      &:active {
        box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.2);
      }

      &:focus {
        box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.3);
      }
    }

    &::-moz-range-thumb {
      height: 20px;
      width: 20px;
      background: var(--secondary-color, $secondary-color);
      border-radius: 50%;
      border: none;
      cursor: pointer;
      transition: background 0.2s ease-in-out;

      &:hover {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
      }

      &:active {
        box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.2);
      }

      &:focus {
        box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.3);
      }
    }
  }
}
</style>
