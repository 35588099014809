<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { storeToRefs } from 'pinia';
import {
  BadgeVariant,
  BreadcrumbPaths,
  BreadcrumbTitles,
  ButtonType,
  ButtonVariant,
  PageNames,
} from '@viewModels/enums';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import Forbidden from '@layouts/Forbidden.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import SubHeader from '@components/SubHeader.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import { extractErrorMessage } from '@utils/errorUtils';
import BadgeComponent from '@components/BadgeComponent.vue';
import Tooltip from '@components/Tooltip.vue';


interface CreateCameraForm {
  balenaUuid: string,
  serialNumber: string;
  wpSerialNumber: string;
  wpImei: string;
}

interface BadgeInput {
  value: string | undefined;
  length: number;
  valid: boolean;
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.Cameras, to: BreadcrumbPaths.Cameras },
  { title: BreadcrumbTitles.CreateCamera, active: true },
];

// Page Constants
const router = useRouter();
const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const isSubmitting = ref<boolean>(false);

// Form Constants
const alphanumericDashRegex = /^[a-zA-Z0-9-]*$/;
const alphanumericRegex = /^[a-zA-Z0-9]*$/;
const serialNumberLength = 10;
const wpSerialNumberLength = 14;
const wpImeiLength = 15;
const balenaUuidLength = 32;

// Form Validation Rules
const { handleSubmit, meta } = useForm<CreateCameraForm>({
  validationSchema: yup.object({
    serialNumber: yup
      .string()
      .required('GabrielCam Serial Number is required')
      .matches(alphanumericDashRegex, 'Only letters, numbers, and hyphens are allowed')
      .length(serialNumberLength, `GabrielCam Serial Number must be exactly ${serialNumberLength} characters`),
    wpSerialNumber: yup
      .string()
      .required('Modem Serial Number is required')
      .matches(alphanumericDashRegex, 'Only letters, numbers, and hyphens are allowed')
      .length(wpSerialNumberLength, `Modem Serial Number must be exactly ${wpSerialNumberLength} characters`),
    wpImei: yup
      .string()
      .required('Modem IMEI is required')
      .matches(alphanumericDashRegex, 'Only letters, numbers, and hyphens are allowed')
      .length(wpImeiLength, `Modem IMEI must be exactly ${wpImeiLength} characters`),
    balenaUuid: yup
      .string()
      .required('Balena Uuid is required')
      .matches(alphanumericRegex, 'Only letters and numbers are allowed')
      .length(balenaUuidLength, `Balena Uuid must be exactly ${balenaUuidLength} characters`),
  }),
});

// Form Values
const { value: serialNumber, meta: serialNumberMeta } = useField<string>(
  'serialNumber',
  { initialTouched: true },
);
const { value: wpSerialNumber, meta: wpSerialNumberMeta } = useField<string>(
  'wpSerialNumber',
  { validateOnValueUpdate: true },
);
const { value: wpImei, meta: wpImeiMeta } = useField<string>(
  'wpImei',
  { validateOnValueUpdate: true },
);
const { value: balenaUuid, meta: balenaUuidMeta } = useField<string>(
  'balenaUuid',
  { validateOnValueUpdate: true },
);

/**
 * Returns the badge variant based on the input value, length, and validity.
 *
 * @param {BadgeInput} input - The input object containing value, length, and valid properties.
 * @returns {BadgeVariant} The badge variant corresponding to the input state.
 */
const getBadgeVariant = ({ value, length, valid }: BadgeInput): BadgeVariant => {
  // If the input is empty
  if (!value || value.length === 0) {
    return BadgeVariant.Outline;
  }
  // If the input is valid and has the correct length
  if (value.length === length && valid) {
    return BadgeVariant.Success;
  }
  // Otherwise
  return BadgeVariant.Danger;
};


/**
 * Handles the form submission for creating a new camera.
 *
 * @param {object} values - The form values submitted by the user.
 */
const onSubmit = handleSubmit(async (values: CreateCameraForm) => {
  isSubmitting.value = true;

  // Check if the active organisation is set before proceeding
  if (!applicationStore.activeOrganisation) return;

  try {
    // Create a new camera using the client API
    await client.createCamera({
      requestBody: {
        serialNumber: values.serialNumber,
        // Set the system type to GabrielCam
        systemType: client.SupportedSystems.GABRIELCAMV1,
        meta: {
          balenaUuid: values.balenaUuid,
          wpImei: values.wpImei,
          wpSerialNumber: values.wpSerialNumber,
        },
      },
    });

    // Publish a success notification to the user
    applicationStore.publishSuccessNotification({
      text: 'Successfully created camera.',
      autoCloseMs: 3000,
    });

    await router.push({ name: PageNames.Cameras });

  } catch (error: unknown) {
    // Extract the error message from the error object
    const errorMessage = extractErrorMessage(error);
    console.error('Error creating camera:', errorMessage);

    applicationStore.publishErrorNotification({
      text: `Error creating camera: ${errorMessage}`,
    });

    isSubmitting.value = false;
    return;
  }
});
</script>

<template>
  <template v-if="adminMode">
    <SubHeader heading="Create Camera"
               level="2" />

    <Breadcrumb :is-sticky="true"
                :items="breadcrumbs" />

    <ContainerCard>
      <form @submit="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              Camera Information
            </Heading>
            <p>Add your camera details and register it in the system.</p>
          </div>

          <div class="fields">
            <div class="row-half">
              <div class="field">
                <div class="d-flex align-center justify-between">
                  <label for="serialNumber">GabrielCam Serial Number</label>
                  <BadgeComponent :variant="getBadgeVariant({ value: serialNumber, length: serialNumberLength, valid: serialNumberMeta.valid })"
                                  :is-pill="true">
                    {{ serialNumber?.length || 0 }}|{{ serialNumberLength }}
                  </BadgeComponent>
                </div>
                <input id="serialNumber"
                       v-model="serialNumber"
                       type="text"
                       placeholder="GC-1234-56"
                       autocomplete="off"
                       :maxlength="serialNumberLength"
                       :class="{ 
                         'input-error': serialNumberMeta.touched && !serialNumberMeta.valid,
                         'input-valid': serialNumberMeta.touched && serialNumberMeta.valid 
                       }"
                       @input="serialNumberMeta.touched = true">
                <ErrorMessage name="serialNumber" class="message message-error" as="p" />
              </div>
              <div class="field" />
            </div>

            <div class="row-half">
              <div class="field">
                <div class="d-flex align-center justify-between">
                  <label for="wpSerialNumber">Modem Serial Number</label>
                  <BadgeComponent :variant="getBadgeVariant({ value: wpSerialNumber, length: wpSerialNumberLength, valid: wpSerialNumberMeta.valid })"
                                  :is-pill="true">
                    {{ wpSerialNumber?.length || 0 }}|{{ wpSerialNumberLength }}
                  </BadgeComponent>
                </div>
                <input id="wpSerialNumber"
                       v-model="wpSerialNumber"
                       type="text"
                       placeholder="12345678901234"
                       autocomplete="off"
                       :maxlength="wpSerialNumberLength"
                       :class="{ 
                         'input-error': wpSerialNumberMeta.touched && !wpSerialNumberMeta.valid, 
                         'input-valid': wpSerialNumberMeta.touched && wpSerialNumberMeta.valid
                       }"
                       @input="wpSerialNumberMeta.touched = true">
                <ErrorMessage name="wpSerialNumber" class="message message-error" as="p" />
              </div>

              <div class="field">
                <div class="d-flex align-center justify-between">
                  <label for="wpImei">Modem IMEI</label>
                  <BadgeComponent :variant="getBadgeVariant({ value: wpImei, length: wpImeiLength, valid: wpImeiMeta.valid })"
                                  :is-pill="true">
                    {{ wpImei?.length || 0 }}|{{ wpImeiLength }}
                  </BadgeComponent>
                </div>
                <input id="wpImei"
                       v-model="wpImei"
                       type="text"
                       placeholder="123456789012345"
                       autocomplete="off"
                       :maxlength="wpImeiLength"
                       :class="{ 
                         'input-error': wpImeiMeta.touched && !wpImeiMeta.valid,
                         'input-valid': wpImeiMeta.touched && wpImeiMeta.valid 
                       }"
                       @input="wpImeiMeta.touched = true">
                <ErrorMessage name="wpImei" class="message message-error" as="p" />
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <div class="d-flex align-center justify-between">
                  <label for="serialNumber">Balena Uuid</label>
                  <BadgeComponent :variant="getBadgeVariant({ value: balenaUuid, length: balenaUuidLength, valid: balenaUuidMeta.valid })"
                                  :is-pill="true">
                    {{ balenaUuid?.length || 0 }}|{{ balenaUuidLength }}
                  </BadgeComponent>
                </div>
                <input id="balenaUuid"
                       v-model="balenaUuid"
                       type="text"
                       placeholder="7db3e8d961f5c38fd52359f6cadfdd40"
                       autocomplete="off"
                       :maxlength="balenaUuidLength"
                       :class="{ 
                         'input-error': balenaUuidMeta.touched && !balenaUuidMeta.valid, 
                         'input-valid': balenaUuidMeta.touched && balenaUuidMeta.valid 
                       }"
                       @input="balenaUuidMeta.touched = true">
                <ErrorMessage name="balenaUuid" class="message message-error" as="p" />
              </div>
              <div class="field" />
            </div>
          </div>
        </div>

        <ButtonContainer>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :loading="isSubmitting"
                           :disabled="!meta.touched || !meta.valid"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Create
          </ButtonComponent>
        </ButtonContainer>
      </form>
    </ContainerCard>
  </template>

  <template v-else>
    <ContainerCard>
      <Forbidden heading-text="Unauthorised Access">
        <p>You do not have permission to access this page</p>
      </Forbidden>
    </ContainerCard>
  </template>
</template>

<style lang="scss" scoped>
:deep(.badge) {
  min-width: 48px;
}
</style>
