<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import {
  BadgeVariant,
  BreadcrumbTitles,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  PageNames,
  UserStatus,
} from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { UserViewModel } from '@viewModels/userViewModel';
import BadgeComponent from '@components/BadgeComponent.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';
import ModalComponent from '@components/ModalComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';
import TableComponent from '@components/table/TableComponent.vue';
import Loading from '@components/Loading.vue';


interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

enum TableHeaders {
  Name = 'Name',
  Email = 'Email',
  Roles = 'Roles',
  Status = 'Status',
  Actions = 'Actions',
}

interface StatusBadge {
  label: UserStatus;
  variant: BadgeVariant;
}

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const grantRoles = applicationStore.canUser(client.Entitlements.GRANT_ROLE, applicationStore.activeOrganisation!);
const revokeRoles = applicationStore.canUser(client.Entitlements.REVOKE_ROLE, applicationStore.activeOrganisation!);
const showInvite = grantRoles;
const showManageRoles = grantRoles || revokeRoles;

const users = ref<UserViewModel[]>([]);
const isLoading = ref<boolean>(true);
const isSubmitting = ref<boolean>(true);
const router = useRouter();

const showDeleteUserModel = ref(false);
const deleteUserModelFunc = ref<Function>(() => deleteUser);
const userName = ref('');


async function resendInvite(user: UserViewModel): Promise<void> {
  isSubmitting.value = true;
  try {
    await client.createInvitation({
      requestBody: {
        email: user.email,
        displayName: user.displayName,
        roles: user.roles,
      },
    });

    applicationStore.publishSuccessNotification({
      text: 'Successfully invited user.',
      autoCloseMs: 3000,
    });

    router.push({ name: PageNames.Users });

  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    } else {
      applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    }
  } finally {
    isSubmitting.value = false;
  }
}

/**
 * Retrieves a list of users for the active organisation, mapping roles and formatting user details.
 *
 * @returns {Promise<UserViewModel[]>} A promise that resolves to an array of user view models.
 */
async function getUsersList(): Promise<UserViewModel[]> {
  isLoading.value = true;

  try {
    // Fetch the list of users for the active organisation
    const usersResponse = await client.listUsers({ organisation: applicationStore.activeOrganisation?.id, type: client.UserTypes.PERSON });

    if (!usersResponse || !usersResponse.data) {
      throw new Error("Invalid response structure from listUsers.");
    }

    // Fetch the list of roles
    const rolesResponse = await client.listRoles();
    const roles = rolesResponse.data || [];

    // Transform the user data
    return usersResponse.data.map((user) => {
      // Map user roles to their corresponding names
      const userRoleNames = user.roles
        .map((role) => roles.find((roleItem) => roleItem.id === role.id)?.shortName)
        .filter((roleName): roleName is string => !!roleName); // Filter out undefined values

      // Remove duplicate role names
      const uniqueRoleNames = [...new Set(userRoleNames)];

      // Determine the user's display name
      const displayName = user.forename && user.surname
        ? `${user.forename} ${user.surname}`
        : user.displayName;

      return {
        id: user.id,
        status: user.status,
        email: user.email,
        displayName: displayName,
        roles: user.roles,
        rolesDisplay: uniqueRoleNames, // Ensure rolesDisplay is string[]
        isAdmin: user.isAdmin ?? false,
        forename: user.forename || '', // Ensure forename is present
        surname: user.surname || '', // Ensure surname is present
      };
    });
  } catch (error) {
    console.error("Error fetching users list:", error);
    throw error;
  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  users.value = await getUsersList();
});

// Table
const tableRef = ref<DirectoryTableType | null>(null);
const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.Name,
    dataField: 'displayName',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '1%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Email,
    dataField: 'email',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '45%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Roles,
    dataField: 'rolesDisplay',
    headerClassName: '',
    columnWidth: '360px',
  },
  {
    labelText: TableHeaders.Status,
    dataField: 'status',
    headerClassName: 'status',
    columnWidth: '1%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Actions,
    dataField: 'id',
    headerClassName: '',
    columnWidth: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'id',
    sort: 'asc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteUserModel.value = false;
}

async function showDeleteConfirmModal(row: client.User): Promise<void> {
  showDeleteUserModel.value = true;
  userName.value = row.displayName;

  deleteUserModelFunc.value = async (): Promise<void> => {
    await deleteUser(row);
  };
}

async function deleteUser(row: client.User): Promise<void> {
  await client.deleteUserById({ userId: row.id });

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: `Successfully deleted ${row.displayName}.`,
    autoCloseMs: 3000,
  });

  // Refresh the table after deletion
  if (tableRef.value) {
    await tableRef.value.reloadData();
  }
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllUsers, active: true },
];

/**
 * Mapping of user statuses. Each status badge has a label and a variant.
 */
const statusBadgeMap: Record<UserStatus, StatusBadge> = {
  [UserStatus.Active]: { label: UserStatus.Active, variant: BadgeVariant.Success },
  [UserStatus.Invited]: { label: UserStatus.Invited, variant: BadgeVariant.Warning },
  [UserStatus.Accepted]: { label: UserStatus.Accepted, variant: BadgeVariant.Info }
};
const getStatusBadge = (status: UserStatus): StatusBadge | undefined => {
  return statusBadgeMap[status];
};
</script>

<template>
  <SubHeader heading="Users"
             level="2">
    <template v-if="showInvite"
              #buttons>
      <ButtonComponent :is-block-btn="true"
                       :to="{ name: PageNames.UserInvitation }"
                       :variant="ButtonVariant.Dark">
        Invite User
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <TableComponent ref="tableRef"
                          :columns="table.columns"
                          :retrieve-data="getUsersList"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No users found"
                          :icon-name="IconName.UserGroupIcon"
                          :icon-style="IconStyle.Outline" />
            </template>

            <template #cell="{ row, column }">
              <div v-if="column.labelText === TableHeaders.Roles" class="d-flex flex-wrap gap-5">
                <div v-for="role in row.rolesDisplay" :key="role">
                  <BadgeComponent :is-pill="true" :variant="BadgeVariant.Light">
                    {{ role }}
                  </BadgeComponent>
                </div>
              </div>

              <div v-if="column.labelText === TableHeaders.Status" class="d-flex justify-center">
                <BadgeComponent v-if="getStatusBadge(row.status)"
                                :is-pill="true"
                                :variant="getStatusBadge(row.status)?.variant">
                  {{ getStatusBadge(row.status)?.label }}
                </BadgeComponent>
              </div>

              <div v-if="column.labelText === TableHeaders.Actions"
                   class="d-flex gap-20">
                <ButtonComponent v-if="showManageRoles && (applicationStore.activeUser?.id !== row.id)"
                                 :size="ButtonSize.Small"
                                 :variant="ButtonVariant.Dark"
                                 @click="() => router.push({ name: PageNames.UserManageRoles, params: { id: row.id } })">
                  Manage Roles
                </ButtonComponent>

                <ButtonComponent v-if="applicationStore.activeUser?.id === row.id"
                                 :size="ButtonSize.Small"
                                 :variant="ButtonVariant.Dark"
                                 @click="() => router.push({ name: PageNames.Account })">
                  Your account
                </ButtonComponent>

                <ButtonComponent v-if="adminMode && !row.isAdmin && (applicationStore.activeUser?.id !== row.id)"
                                 :size="ButtonSize.Small"
                                 :variant="ButtonVariant.Dark"
                                 @click="() => router.push({ name: PageNames.UserEdit, params: { id: row.id } })">
                  Edit User
                </ButtonComponent>
                <ButtonComponent v-if="adminMode && !row.isAdmin"
                                 :size="ButtonSize.Small"
                                 :variant="ButtonVariant.Danger"
                                 @click="showDeleteConfirmModal(row)">
                  Delete User
                </ButtonComponent>
                <ButtonComponent v-if="showInvite && row.status === UserStatus.Invited"
                                 :size="ButtonSize.Small"
                                 :variant="ButtonVariant.Info"
                                 @click="resendInvite(row)">
                  Resend Invite
                </ButtonComponent>
              </div>
            </template>
          </TableComponent>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete User Modal -->
  <ModalComponent :visible="showDeleteUserModel && adminMode"
                  heading-title="Delete User"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the account <span class="text--semibold">{{ userName }}</span> this cannot be undone.
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Submit"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteUserModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
