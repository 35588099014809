<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import {
  BadgeVariant,
  BreadcrumbPaths,
  BreadcrumbTitles,
  ButtonType,
  ButtonVariant,
  PageNames,
} from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import SubHeader from '@components/SubHeader.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import { extractErrorMessage } from '@utils/errorUtils';
import BadgeComponent from '@components/BadgeComponent.vue';

interface RegisterCameraForm {
  serialNumber: string;
}

interface BadgeInput {
  value: string | undefined;
  length: number;
  valid: boolean;
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.Cameras, to: BreadcrumbPaths.Cameras },
  { title: BreadcrumbTitles.RegisterCamera, active: true },
];

// Page Constants
const router = useRouter();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);

// Form Constants
const alphanumericDashRegex = /^[a-zA-Z0-9-]*$/;
const serialNumberLength = 10;

// Form validation rules
const { handleSubmit, meta } = useForm<RegisterCameraForm>({
  validationSchema: yup.object({
    serialNumber: yup
      .string()
      .required('GabrielCam Serial Number is required')
      .matches(alphanumericDashRegex, 'Only letters, numbers, and hyphens are allowed')
      .length(serialNumberLength, `GabrielCam Serial Number must be exactly ${serialNumberLength} characters`),
  }),
});

// Form values
const { value: serialNumber, meta: serialNumberMeta, resetField: resetSerialNumber } = useField<string>('serialNumber');


/**
 * Returns the badge variant based on the input value, length, and validity.
 *
 * @param {BadgeInput} input - The input object containing value, length, and valid properties.
 * @returns {BadgeVariant} The badge variant corresponding to the input state.
 */
const getBadgeVariant = ({ value, length, valid }: BadgeInput): BadgeVariant => {
  // If the input is empty
  if (!value || value.length === 0) {
    return BadgeVariant.Outline;
  }
  // If the input is valid and has the correct length
  if (value.length === length && valid) {
    return BadgeVariant.Success;
  }
  // Otherwise
  return BadgeVariant.Danger;
};


/**
 * Submit handler for the camera registration form.
 *
 * This function is called when the form is submitted. It handles the submission
 * by registering the camera with the client API and displaying a success or error
 * notification to the user.
 *
 * @param values The form values, which include the system serial number.
 */
const onSubmit = handleSubmit(async (values: RegisterCameraForm) => {
  isSubmitting.value = true;

  // Check if the active organisation is set before proceeding
  if (!applicationStore.activeOrganisation) {
    // If no organisation is set, exit the function early
    return;
  }

  try {
    // Register the camera with the client API
    await client.registerCamera({
      requestBody: {
        serialNumber: values.serialNumber,
        organisation: applicationStore.activeOrganisation.id,
      },
    });

    applicationStore.publishSuccessNotification({
      text: 'Successfully created camera.',
      autoCloseMs: 3000,
    });

    await router.push({ name: PageNames.Cameras });

  } catch (error: unknown) {
    // Extract the error message from the error object
    const errorMessage = extractErrorMessage(error);
    console.error('Error creating camera:', errorMessage);

    applicationStore.publishErrorNotification({
      text: `Error creating camera: ${errorMessage}`,
    });

    // Reset the touched state after failed submission
    // This disables the submit button and retains the input values
    resetSerialNumber({ value: serialNumber.value, touched: false });

  } finally {
    isSubmitting.value = false;
  }
});
</script>

<template>
  <SubHeader heading="Register Camera"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Camera Information
          </Heading>
          <p>Add your camera details and register it in the system.</p>
        </div>

        <div class="fields">
          <div class="row-half">
            <div class="field">
              <div class="d-flex align-center justify-between">
                <label for="serialNumber">GabrielCam Serial Number</label>
                <BadgeComponent :variant="getBadgeVariant({ value: serialNumber, length: serialNumberLength, valid: serialNumberMeta.valid })"
                                :is-pill="true">
                  {{ serialNumber?.length || 0 }}|{{ serialNumberLength }}
                </BadgeComponent>
              </div>
              <input id="serialNumber"
                     v-model="serialNumber"
                     type="text"
                     placeholder="GC-1234-56"
                     autocomplete="off"
                     :maxlength="serialNumberLength"
                     :class="{
                       'input-error': serialNumberMeta.touched && !serialNumberMeta.valid,
                       'input-valid': serialNumberMeta.touched && serialNumberMeta.valid
                     }"
                     @input="serialNumberMeta.touched = true">
              <ErrorMessage name="serialNumber" class="message message-error" as="p" />
            </div>
          </div>
        </div>
      </div>

      <ButtonContainer>
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :loading="isSubmitting"
                         :disabled="!meta.touched || !meta.valid"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Register
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </ContainerCard>
</template>

<style lang="scss" scoped>
:deep(.badge) {
  min-width: 48px;
}
</style>
