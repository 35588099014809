<script setup lang="ts">
import ViewDirectorySearchInput from '@components/view/ViewDirectorySearchInput.vue';

// Query Parameters Interface
export interface ViewDirectorySearchQueryParameters {
  search?: string;
}

// Emit Type
const emit = defineEmits<{ (e: 'search', search: ViewDirectorySearchQueryParameters): void }>();

// Search Function
const search = async (searchText: string): Promise<void> => {
  emit('search', { search: searchText });
};

// Clear Function
const clearSearch = async (): Promise<void> => {
  emit('search', { search: '' }); // Emit empty search to clear
};
</script>

<template>
  <ViewDirectorySearchInput placeholder="Search by view"
                            :on-search-function="search"
                            :on-clear-function="clearSearch" />
</template>
