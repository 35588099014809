<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import Loading from '@components/Loading.vue';
import { TableComponentColumn, TableComponentSetting } from './models/TableComponentModels';
import ResourcePagination from '@components/ResourcePagination.vue';
import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/vue/24/outline';
import EmptyState from '@layouts/EmptyState.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { XMarkIcon } from '@heroicons/vue/24/solid';

/**
 * TableComponent Component
 *
 * This component is a reusable table designed to handle both static and dynamic data with support for
 * searching, sorting, and pagination.
 *
 * ## Props
 *
 * @prop {() => Promise<T[]>} [retrieveData] (Optional) - A function for dynamic data fetching.
 * Expected to return a Promise resolving to an array of type T.
 *
 * @prop {T[]} [data] (Optional) - Static data passed directly to the table. Ignored if `retrieveData` is provided.
 *
 * @prop {TableComponentColumn[]} columns (Required) - An array of column definitions. Each column should
 * adhere to the `TableComponentColumn` interface:
 *   - `labelText: string` - The displayed label for the column.
 *   - `dataField: string` - The field key in the data objects.
 *   - `headerClassName?: string` - (Optional) CSS classes for the column header.
 *   - `columnWidth?: string | number` - (Optional) Custom width for the column.
 *   - `headerStyle?: string` - (Optional) Inline styles for the header.
 *   - `isSortable?: boolean` - (Optional) Indicates if the column supports sorting.
 *
 * @prop {object} [sortable] (Optional) - Specifies the default sort behavior:
 *   - `order: string` - The data field to sort by.
 *   - `sort: 'asc' | 'desc'` - The initial sort direction.
 *
 * ## Emits
 * This component exposes an event to refresh the table data:
 * - `reloadData`: Can be triggered via the component's `ref` for manual table reloads.
 *
 * ## Exposed Methods
 * - `reloadData()`: Refreshes the table data by calling the provided `retrieveData` function.
 *
 * ## Features
 * - **Dynamic Data**: Supports fetching data dynamically using the `retrieveData` prop.
 * - **Static Data**: Allows passing preloaded data using the `data` prop.
 * - **Sorting**: Users can click on sortable columns to toggle sorting order (ascending/descending).
 * - **Searching**: Includes a search input for filtering rows.
 * - **Pagination**: Displays paginated results with customisable page size.
 *
 * ## Example Usage
 *
 * ### Static Data:
 * ```vue
 * <TableComponent
 *   :data="staticData"
 *   :columns="columns"
 *   :sortable="{ order: 'name', sort: 'asc' }"
 * />
 * ```
 *
 * ### Dynamic Data:
 * ```vue
 * <TableComponent
 *   :retrieve-data="fetchData"
 *   :columns="columns"
 *   :sortable="{ order: 'createdAt', sort: 'desc' }"
 * />
 * ```
 *
 * ### Slots
 * - `#table-empty`: Custom content to show when the table has no data.
 * - `#search-empty`: Custom content to show when no search results are found.
 * - `#cell`: Custom rendering for individual table cells. Provides:
 *   - `row`: The row object.
 *   - `column`: The column definition.
 *   - `value`: The resolved value for the cell.
 *
 * ## Notes
 * - Either `data` or `retrieveData` must be provided for the table to function.
 * - Ensure `retrieveData` returns data in the expected format, matching the `columns` definitions.
 */

interface DirectoryTableProps<T = any> {
  retrieveData?: () => Promise<T[]>; // Optional, This function is used for dynamic data and should return a Promise that resolves to an array of type T.
  data?: T[]; // Optional, This function is used for static data and should return an array of type T.
  columns: Array<TableComponentColumn>;
  sortable?: {
    order: string;
    sort: 'asc' | 'desc';
  };
}

// Props
const props = defineProps<DirectoryTableProps>();

// Table Settings
const setting = reactive<TableComponentSetting>({
  currentPage: 1,
  offset: 0,
  itemsPerPage: 24,
  sortColumn: props.sortable?.order || '',
  sortDirection: props.sortable?.sort || 'asc',
  totalItems: 0,
});

// Loading
const state = reactive({ isLoading: false });

// Data
const rows = reactive<{ data: any[] }>({
  data: [],
});

/**
 * Watches for changes to the `data` when used as a prop and updates the `rows.data` accordingly.
 * This ensures the table data remains reactive and up-to-date.
 */
watch(
  () => props.data,
  (newData) => {
    if (newData) {
      rows.data = [...newData]; // Replace the array to ensure reactivity
    } else {
      rows.data = []; // Handle empty or undefined data
    }
  },
  { immediate: true } // Run immediately on component initialisation
);



// Search
const searchInput = ref<HTMLInputElement | null>(null);
const searchQuery = ref('');
const isSearchEmpty = computed(() => {
  return searchQuery.value.length > 0 && filteredRows.value.length === 0;
});
// Filtered rows based on search query
const filteredRows = computed(() =>
  rows.data.filter((row) =>
    props.columns.some((col) =>
      String(getNestedProp(row, col.dataField) || '')
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase()),
    ),
  ),
);

// Paginated data based on filtered rows
const filteredData = computed(() => {
  const start = (setting.currentPage - 1) * setting.itemsPerPage;
  const end = start + setting.itemsPerPage;

  // Apply pagination AFTER filtering all rows
  return filteredRows.value.slice(start, end);
});

// Pagination Total for filtered rows
const paginationTotal = computed(() => filteredRows.value.length);

// Watch the search query and reset pagination
watch(searchQuery, () => {
  setting.currentPage = 1; // Reset to the first page when the search query changes
});

// Clear Search
function clearSearch(): void {
  searchQuery.value = '';
  setting.currentPage = 1; // Reset pagination
  searchInput.value?.focus(); // Focus back to the input element
}

// Function to load data
async function loadData(): Promise<void> {
  state.isLoading = true;

  if (props.data && props.data.length) {
    // Use static data
    rows.data = [...props.data];
    setting.totalItems = props.data.length;
  } else if (props.retrieveData) {
    // Fetch dynamic data
    rows.data = await props.retrieveData();
    setting.totalItems = rows.data.length;
  }

  state.isLoading = false;
}

function onPageChanged(newPage: number): void {
  setting.currentPage = newPage;
}

function onLimitChanged(newLimit: number): void {
  setting.itemsPerPage = newLimit;
  setting.currentPage = 1; // Reset to the first page when limit changes
}

// Function to handle sorting
function doSort(column: string, direction?: 'asc' | 'desc'): void {
  if (setting.sortColumn === column && !direction) {
    // Toggle only if direction is not explicitly passed
    setting.sortDirection = setting.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    setting.sortColumn = column;
    setting.sortDirection = direction || 'asc'; // Default to 'asc' if no direction is provided
  }

  // Perform the sorting logic here
  rows.data.sort((a, b) => {
    const valA = getNestedProp(a, column) as string | number;
    const valB = getNestedProp(b, column) as string | number;

    if (valA == null || valB == null) return 0;
    if (typeof valA === 'string' && typeof valB === 'string') {
      return setting.sortDirection === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
    }
    if (typeof valA === 'number' && typeof valB === 'number') {
      return setting.sortDirection === 'asc' ? valA - valB : valB - valA;
    }
    return 0;
  });
}

// Function to reload data
async function reloadData(): Promise<void> {
  state.isLoading = true;

  if (props.retrieveData) {
    rows.data = await props.retrieveData();
  } else {
    console.warn("No retrieveData function provided. Cannot reload data.");
    rows.data = []; // Optional: Clear the rows if no retrieveData is available
  }

  state.isLoading = false;
}

// Utility function to get nested properties
function getNestedProp(obj: any, path: string): unknown {
  return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
}

// Expose functions to parent component so we can reload the table
defineExpose({ reloadData });

// Initial data load
await loadData();

// Apply initial sorting based on props.sortable.order
if (props.sortable?.order) {
  doSort(props.sortable.order, props.sortable.sort);
}
</script>

<template>
  <div class="directory-table">
    <Loading v-if="state.isLoading" />

    <template v-else>
      <!-- Search Input -->
      <div class="directory-table__search">
        <input id="search-input"
               ref="searchInput"
               v-model="searchQuery"
               aria-label="Search"
               type="search"
               autocomplete="off"
               placeholder="Search...">
        <XMarkIcon v-if="searchQuery"
                   class="directory-table__search--clear-icon"
                   @click="clearSearch" />
      </div>

      <!-- Table Wrapper -->
      <div class="directory-table__wrapper" :class="{ 'directory-table__wrapper--border': isSearchEmpty || !filteredData.length }">
        <!-- If Search Result is empty use slot or fallback -->
        <template v-if="isSearchEmpty">
          <slot name="search-empty">
            <EmptyState heading-text="No results found"
                        strap-line="Try adjusting your search criteria"
                        :icon-name="IconName.MagnifyingGlassIcon"
                        :icon-style="IconStyle.Outline" />
          </slot>
        </template>

        <!-- If Table is empty use slot or fallback -->
        <template v-else-if="!filteredData.length">
          <slot name="table-empty">
            No data available
          </slot>
        </template>

        <!-- Table -->
        <table v-else class="directory-table__table">
          <thead>
            <tr>
              <th v-for="(col, index) in props.columns"
                  :key="index"
                  :class="col.headerClassName"
                  :style="{
                    width: col.columnWidth || 'auto',
                    ...(typeof col.headerStyle === 'object' ? col.headerStyle : {}),
                  }"
                  @click="col.isSortable && doSort(col.dataField)">
                <div :class="{
                  sortable: col.isSortable,
                  asc: setting.sortColumn === col.dataField && setting.sortDirection === 'asc',
                  desc: setting.sortColumn === col.dataField && setting.sortDirection === 'desc',
                }">
                  {{ col.labelText }}
                  <template v-if="col.isSortable">
                    <template v-if="setting.sortColumn === col.dataField">
                      <ArrowUpIcon v-if="setting.sortDirection === 'asc'" class="sort-icon" />
                      <ArrowDownIcon v-else class="sort-icon" />
                    </template>
                    <ArrowsUpDownIcon v-else class="sort-icon" />
                  </template>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, i) in filteredData" :key="i">
              <td v-for="(col, j) in props.columns"
                  :key="j"
                  :class="col.headerClassName"
                  :style="{ minWidth: col.columnWidth || 'auto' }">
                <slot name="cell"
                      :row="row"
                      :column="col"
                      :value="getNestedProp(row, col.dataField)">
                  {{ getNestedProp(row, col.dataField) }}
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <ResourcePagination :total="paginationTotal"
                          :page="setting.currentPage"
                          :limit="setting.itemsPerPage"
                          @page-changed="onPageChanged"
                          @limit-changed="onLimitChanged" />
    </template>
  </div>
</template>


<style lang="scss" scoped>
@use '@scss/variables' as *;

.directory-table {
  &__search {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: $margin-bottom;

    &--clear-icon {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      fill: $neutral-600;
      transition: fill 300ms ease;

      &:hover {
        fill: $neutral-800;
      }
    }
  }

  &__wrapper {
    margin-bottom: $margin-bottom;
    overflow-x: auto;
    overflow-y: hidden; /* Prevent vertical scroll bars */
    font-size: 0.875rem;
    border-radius: 10px;
    border: 1px solid $neutral-300;
    clip-path: inset(0 round 10px); /* Clip corners on bottom Scrollbar */

    &::-webkit-scrollbar {
      height: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $neutral-400;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $neutral-200;
      border-radius: 10px;
    }

    &--border {
      border: none;
    }
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    background: $neutral-100;
    border-radius: 10px;

    thead {
      font-weight: bold;

      th {
        padding: 12px 8px;
        text-align: left;
        background: $neutral-200;
        border-bottom: 1px solid $neutral-300;

        &:not(:last-child) {
          border-right: 1px solid $neutral-300;
        }

        &.sortable {
          cursor: pointer;
        }

        &:hover {
          background: $neutral-300;
        }

        & .sortable {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          line-height: normal;
          cursor: pointer;

          & .sort-icon {
            width: 1rem;
            height: 1rem;
            fill: $neutral-500;
          }
        }
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid $neutral-300;
        }

        &:nth-child(odd) {
          background: $neutral-50;
        }

        &:hover {
          background: $neutral-200;
        }

        td {
          padding: 4px 8px;

          &:not(:last-child) {
            border-right: 1px solid $neutral-300;
          }
        }
      }
    }
  }
}
</style>
