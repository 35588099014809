<script setup lang="ts">
import * as client from '@gabrielcam/api-client';
import { useField, useForm } from 'vee-validate';
import { ref, watchEffect } from 'vue';
import * as yup from 'yup';
import { useApplicationStore } from '@stores/application';
import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import Tooltip from '@components/Tooltip.vue';
import { ButtonType, ButtonVariant } from '@viewModels/enums';
import Heading from '@components/Heading.vue';

interface AssignRoleForm {
  id: string;
  role: string;
  relId: string;
}

interface ResourceViewModel {
  id: string;
  displayName: string;
}

// Define Props
const props = defineProps<{
  user: client.User,
  isLoading: boolean
  projects: client.Project[],
  views: client.View[],
  roles: client.Role[]
}>()

// Emits
const emit = defineEmits(['roles-updated']);

// Setup
const applicationStore = useApplicationStore();
const filteredResources = ref<ResourceViewModel[]>([]);
const filteredRoles = ref<client.Role[]>(props.roles);

// Forms Schema
const schema = yup.object({
  role: yup.string().required(),
  relId: yup.string().required(),
});

// Form Setup
const selectedRole = ref<client.Role>();
const { handleSubmit } = useForm<AssignRoleForm>({
  validationSchema: schema,
});

// Form Validation
const { value: roleValue, errorMessage: roleError } = useField<string>('role', 'role');
const { value: relIdValue, errorMessage: relIdError } = useField<string>('relId', 'relId');

// Loading
const isSubmitting = ref<boolean>(false);

function roleSelected(role: client.Role): void {
  selectedRole.value = role;
}

// Form Submit
const onSubmit = handleSubmit(async (values: AssignRoleForm, { setFieldValue }) => {
  if (!applicationStore.activeOrganisation) return;

  isSubmitting.value = true;
  await client.assignUserByIdRoles({
    userId: props.user.id,
    requestBody: {
      id: values.role,
      relId: values.relId,
      organisation: applicationStore.activeOrganisation!.id,
    },
  });

  applicationStore.publishSuccessNotification({
    text: 'Role assigned.',
    autoCloseMs: 3000,
  });

  emit('roles-updated')
  setFieldValue('relId', filteredResources.value.at(0)?.id ?? '');
  isSubmitting.value = false;
});

watchEffect(() => {
  filteredRoles.value = props.roles
})

watchEffect(() => {
  relIdValue.value = '';
  filteredResources.value = [];
  const selectedRole = props.roles.find((x) => x.id == roleValue.value);
  if (!selectedRole) return;
  let resources: ResourceViewModel[] = [];
  if (selectedRole.relType == client.Resources.ORGANISATION) {
    resources = [{ id: applicationStore.activeOrganisation!.id, displayName: applicationStore.activeOrganisation!.name }];
  } else if (selectedRole.relType == client.Resources.VIEW) {
    resources = props.views.map((x) => ({ id: x.id, displayName: x.name }));
  } else if (selectedRole.relType == client.Resources.PROJECT) {
    resources = props.projects.map((x) => ({ id: x.id, displayName: x.name }));
  }

  // Strip out any already assigned resource the user may have from the list of available
  const assignedRoleResourceIds = props.user.roles.map((role) => `${role.id}_${role.relId}`)
  filteredResources.value = resources.filter((resource) =>  !assignedRoleResourceIds.includes(`${selectedRole.id}_${resource.id}`));
  relIdValue.value = filteredResources.value.at(0)?.id ?? '';
})
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Assign a Role
        </Heading>
        <p>Assign roles to the user within your organisation.</p>
      </div>
      <div class="fields">
        <div class="row-half">
          <div class="field">
            <span class="label">
              Role <Tooltip v-if="selectedRole">{{ selectedRole.description }}</Tooltip>
            </span>
            <v-select v-model="roleValue" 
                      input-id="Role"
                      label="shortName"
                      autocomplete="off"
                      aria-label="Select Role"
                      placeholder="Select a Role"
                      :options="filteredRoles"
                      :reduce="(role: client.Role) => role.id"
                      :clearable="false"
                      @search="
                        (search: string) => {
                          filteredRoles = props.roles.filter((x) => {
                            if (!!search.length) return true;
                            return x.shortName.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      "
                      @option:selected="roleSelected" />
            <p class="message message-error">
              {{ roleError }}
            </p>
          </div>

          <div v-if="selectedRole" class="field">
            <span class="label">{{ selectedRole.relType }}</span>
            <v-select v-model="relIdValue"
                      input-id="Resource"
                      aria-label="Select Resource"
                      placeholder="Select a Resource"
                      label="displayName"
                      :options="filteredResources"
                      :reduce="(resource: ResourceViewModel) => resource.id"
                      :clearable="false"
                      @search="
                        (search: string) => {
                          filteredResources = filteredResources.filter((x) => {
                            if (!!search.length) return true;
                            return x.displayName.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
            <p class="message message-error">
              {{ relIdError }}
            </p>
          </div>
        </div>

        <div class="row-half">
          <ButtonContainer>
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :disabled="isSubmitting"
                             :type="ButtonType.Submit"
                             :is-block-btn="true">
              Assign Role
            </ButtonComponent>
          </ButtonContainer>
        </div>
      </div>
    </div>
  </form>
</template>
