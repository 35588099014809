<template>
  <main class="app-content">
    <slot name="router-view" />
    <slot name="footer" />
  </main>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.app-content {
  --_left-sidebar-width: 230px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100%;
  padding: $gap-default clamp($gap-mobile, 3vw, $gap-desktop) calc($gap-default * 5);
  background-image: linear-gradient(170deg, var(--secondary-color, $secondary-color, 0.5) 20%, transparent 100%);

  @media screen and (width >= $breakpoint-lg) {
    left: var(--_left-sidebar-width);
    width: calc(100% - var(--_left-sidebar-width));
    padding-bottom: $gap-default;
  }
}
</style>
