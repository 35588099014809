<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonIconGroup from '@components/ButtonIconGroup.vue';

const route = useRoute();
const router = useRouter();
const isViewBtnDisabled = computed(() => route.name === PageNames.Views);
const isMapViewBtnDisabled = computed(() => route.name === PageNames.ViewMap);
const isWallboardViewBtnDisabled = computed(() => route.name === PageNames.ViewWallboard);

const handleNavigation = (name: string, disabled: boolean): void => {
  if (!disabled) {
    router.push({ name: name });
  }
};
</script>

<template>
  <ButtonIconGroup :variant="ButtonVariant.Dark">
    <ButtonComponent :icon-style="IconStyle.Outline"
                     :icon-name="IconName.PhotoIcon"
                     :is-outline-btn="!isViewBtnDisabled"
                     :is-icon-btn="true"
                     :no-border="true"
                     :disabled="isViewBtnDisabled"
                     :variant="ButtonVariant.Dark"
                     @click="handleNavigation(PageNames.Views, isViewBtnDisabled)">
      View
    </ButtonComponent>
    <ButtonComponent :icon-style="IconStyle.Outline"
                     :icon-name="IconName.MapPinIcon"
                     :is-outline-btn="!isMapViewBtnDisabled"
                     :is-icon-btn="true"
                     :no-border="true"
                     :variant="ButtonVariant.Dark"
                     :disabled="isMapViewBtnDisabled"
                     @click="handleNavigation(PageNames.ViewMap, isMapViewBtnDisabled)">
      Map View
    </ButtonComponent>
    <ButtonComponent :icon-style="IconStyle.Outline"
                     :icon-name="IconName.ComputerDesktopIcon"
                     :is-outline-btn="!isWallboardViewBtnDisabled"
                     :is-icon-btn="true"
                     :no-border="true"
                     :variant="ButtonVariant.Dark"
                     :disabled="isWallboardViewBtnDisabled"
                     @click="handleNavigation(PageNames.ViewWallboard, isWallboardViewBtnDisabled)">
      Wallboard View
    </ButtonComponent>
  </ButtonIconGroup>
</template>
