<script setup lang="ts">
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import { BreadcrumbTitles, ButtonSize, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import EmptyState from '@layouts/EmptyState.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableComponentColumn } from '@components/table/models/TableComponentModels';
import TableComponent from '@components/table/TableComponent.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';

interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

enum TableHeaders {
  Name = 'Name',
  Edit = 'Edit',
}

const router = useRouter();

async function getOrganisationList(): Promise<client.Organisation[]> {
  return (await client.listOrganisations()).data;
}

// Table
const tableRef = ref<DirectoryTableType | null>(null);
const columns: TableComponentColumn[] = [
  {
    labelText: TableHeaders.Name,
    dataField: 'name',
    headerClassName: 'text--white-space-nowrap',
    columnWidth: '99%',
    isSortable: true,
  },
  {
    labelText: TableHeaders.Edit,
    dataField: 'id',
    columnWidth: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'name',
    sort: 'asc',
  } as const, // Type assertion to enforces 'asc' | 'desc'
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllOrganisations, active: true },
];
</script>

<template>
  <SubHeader heading="Organisations"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <div class="main-wrapper">
        <TableComponent ref="tableRef"
                        :retrieve-data="getOrganisationList"
                        :columns="table.columns"
                        :sortable="table.sortable">
          <template #table-empty>
            <EmptyState heading-text="No organisations found"
                        :icon-name="IconName.UserGroupIcon"
                        :icon-style="IconStyle.Outline" />
          </template>

          <template #cell="{ row, column }">
            <div v-if="column.labelText === TableHeaders.Name"
                 class="text--truncate">
              {{ row.name }}
            </div>

            <div v-if="column.labelText === TableHeaders.Edit"
                 class="d-flex gap-20">
              <ButtonComponent :size="ButtonSize.Small"
                               :variant="ButtonVariant.Dark"
                               @click="() => router.push({ name: PageNames.OrganisationSettings, params: { id: row.id } })">
                Edit
              </ButtonComponent>
            </div>
          </template>
        </TableComponent>
      </div>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
