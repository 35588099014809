import { useMobileDetection } from '@utils/isMobile';

export function useMobileTooltip(): { isMobile: boolean; onShow: () => void; onHide: () => void } {
  const { isMobile } = useMobileDetection();
  let count = 0;

  const onShow = (): void => {
    if (count === 0) {
      document.body.classList.add('no-scroll');
    }
    count++;
  };

  const onHide = (): void => {
    count--;
    if (count === 0) {
      document.body.classList.remove('no-scroll');
    }
  };

  return {
    isMobile: isMobile.value, // Access reactive value
    onShow,
    onHide,
  };
}
