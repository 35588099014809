<script setup lang="ts">
import { onMounted, Ref, ref, watch } from 'vue';
import { useApplicationStore } from '@stores/application';
import { dateTimeFormat } from '@utils/date';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useVideoStore, Video } from '@stores/video';
import { ButtonVariant, PageNames } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Directory from '@components/directory/Directory.vue';
import ModalComponent from '@components/ModalComponent.vue';
import VideoDirectoryToolbar from '@components/video/VideoDirectoryToolbar.vue';
import VideoDirectoryPagination from '@components/video/VideoDirectoryPagination.vue';
import VideoPlayer from '@components/video/VideoPlayer.vue';
import VideoCard from '@components/cards/VideoCard.vue';
import HorizontalRule from '@components/HorizontalRule.vue';

// Stores
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

const videoStore = useVideoStore();
const route = useRoute();
const { videos } = storeToRefs(videoStore);
const pageName = ref<PageNames>(route.name as PageNames);

const selectedFilters = ref({
  client: undefined,
}) as Ref<{ client: string | undefined }>;

const viewId = ref<string>();
const sortBy = ref<string | undefined>();

// Permissions
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_SEQUENCE, applicationStore.activeOrganisation!);

// Video Player Modal
const videoPlayerVisible = ref(false);
const videoPlayerUrl = ref<string>('');
const videoSequenceId = ref<string>();
const videoCreatedAt = ref<string>();

// Delete Video Modal
const deleteVideoVisible = ref(false);
const videoToDelete = ref<string>();

watch(
  () => route.params['id'],
  async (viewId) => {
    await videoStore.getVideos(viewId as string, sortBy.value);
  }
);

async function updateSort(sortBy: string, sortOrder: string): Promise<void> {
  const sortValue = `${sortOrder}${sortBy}`;
  // Fetch sorted videos
  await videoStore.getVideos(viewId.value, sortValue);
}

onMounted(async () => {
  viewId.value = route.params['id'] as string | undefined;

  await videoStore.getVideos(viewId.value);
});

function closeVideoPlayerModal(): void {
  videoPlayerVisible.value = false;
}
function openVideoPlayer(video: Video): void {
  videoPlayerVisible.value = true;
  videoPlayerUrl.value = video.url ?? '';
}

// Open Delete Video Modal
function openDeleteVideoModal(video: Video): void {
  deleteVideoVisible.value = true;
  // Pass the video details to the modal
  videoToDelete.value = video.viewName ?? 'Unknown';
  videoSequenceId.value = video.id;
  videoCreatedAt.value = dateTimeFormat(activeUser?.timezone).format(new Date(video.createdAt));
}

// Close Delete Video Modal
function closeDeleteVideoModal(): void {
  deleteVideoVisible.value = false;
}

// Delete Video
async function handleDeleteVideo(): Promise<void> {
  try {
    await videoStore.deleteVideo({ sequenceId: videoSequenceId.value as string });
    videoSequenceId.value = ''; // Clear the videoSequenceId
  } catch (error) {
    console.error('Error in handleDeleteVideo:', error);
    // Error notifications are already handled in the store
  } finally {
    closeDeleteVideoModal();
  }
}
</script>

<template>
  <ContainerCard>
    <VideoDirectoryToolbar @select-client="(clientId: string) => (selectedFilters.client = clientId)"
                           @sort-view-by="updateSort" />

    <Directory :object-collection-reference="videos.data" :page-name="pageName">
      <template #default="scope">
        <VideoCard :resource="scope.resource"
                   @play-video="openVideoPlayer(scope.resource)"
                   @delete-video="openDeleteVideoModal(scope.resource)" />
      </template>
    </Directory>

    <HorizontalRule />

    <VideoDirectoryPagination />
  </ContainerCard>

  <!-- Video Player Modal -->
  <ModalComponent :visible="videoPlayerVisible"
                  heading-title="Watch Video"
                  @on-close="closeVideoPlayerModal">
    <template #modal-content>
      <VideoPlayer :video-src="videoPlayerUrl" />
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeVideoPlayerModal">
        Close
      </ButtonComponent>
    </template>
  </ModalComponent>

  <!-- Delete Video Modal -->
  <ModalComponent :visible="deleteVideoVisible"
                  heading-title="Delete Video"
                  @on-close="closeDeleteVideoModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--semibold">{{ videoToDelete }}</span> video created at <span class="text--semibold">{{ videoCreatedAt }}</span>?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteVideoModal">
        Close
      </ButtonComponent>
      <ButtonComponent :disabled="!showDelete"
                       :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="handleDeleteVideo()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
