<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { firebaseApp } from '@utils/firebase';
import { useApplicationStore } from '@stores/application';
import { extractErrorMessage } from '@utils/errorUtils';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const applicationStore = useApplicationStore();
const router = useRouter();
const isPasswordVisible = ref(false);

interface ChangePasswordForm {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

/**
 * Define the validation schema for the change password form.
 *
 * The schema consists of three fields: currentPassword, password, and confirmPassword.
 * Each field has its own set of validation rules.
 */
const { handleSubmit, isSubmitting, meta } = useForm<ChangePasswordForm>({
  validationSchema: yup.object({
    /**
     * Current password field.
     *
     * - Required: The field must not be empty.
     */
    currentPassword: yup
      .string()
      .required('Current password is required'),

    /**
     * New password field.
     *
     * - Required: The field must not be empty.
     * - Min length: The password must be at least 8 characters long.
     * - Must contain at least one lowercase letter.
     * - Must contain at least one uppercase letter.
     * - Must contain at least one number.
     * - Must contain at least one special character.
     */
    password: yup
      .string()
      .trim()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(200, 'Password must be less than 200 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(/[!@#$£%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),

    /**
     * Confirm password field.
     *
     * - Required: The field must not be empty.
     * - Must match the password field.
     */
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Please confirm your password'),
  }),
});

/**
 * The `useField` hook is used to create a form field with validation.
 * @param {string} field - The name of the field.
 * @param {object} options - The options for the field.
 * @param {boolean} options.validateOnValueUpdate - Whether to revalidate the field when its value changes.
 */
const { value: currentPasswordValue, meta: currentPasswordMeta } = useField<string>('currentPassword', { validateOnValueUpdate: true });
const { value: passwordValue, meta: passwordMeta } = useField<string>('password', { validateOnValueUpdate: true });
const { value: confirmPasswordValue, meta: confirmPasswordMeta } = useField<string>('confirmPassword', { validateOnValueUpdate: true });

/**
 * Handles the form submission for the change password form.
 *
 * This function is called when the user submits the form. It first checks if the user is authenticated and if so,
 * it attempts to re-authenticate the user with their current password. If successful, it proceeds to update the user's password.
 *
 * @param {Object} values - The form values submitted by the user.
 * @param {Function} actions - The Vee-Validate actions object.
 * @returns {Promise<void>} - A Promise that resolves when the form submission is complete.
 */
const onSubmit = handleSubmit(async (values, actions) => {
  // Get the authentication provider
  const authProvider = getAuth(firebaseApp);

  // Ensure the user is authenticated
  if (!authProvider.currentUser || !authProvider.currentUser.email) return;

  try {
    // Re-authenticate the user with their current password
    const credentials = EmailAuthProvider.credential(
      authProvider.currentUser.email,
      values.currentPassword
    );
    // Re-authenticate the user
    await reauthenticateWithCredential(authProvider.currentUser, credentials);
  } catch (error: unknown) {
    console.error('Re-authentication failed:', error);

    // Check if the error is a Firebase error
    if (error instanceof FirebaseError) {
      // Handle specific Firebase error codes
      if (error.code === 'auth/invalid-credential') {
        actions.setFieldError(
          'currentPassword',
          'Invalid login details. Please check your password and try again.'
        );
        return;
      }
    }

    // Fallback for unexpected errors
    actions.setFieldError('currentPassword', extractErrorMessage(error));
    return;
  }

  try {
    // Update the password after successful re-authentication
    await updatePassword(authProvider.currentUser, values.password);

    applicationStore.publishSuccessNotification({
      text: 'Successfully updated password.',
      autoCloseMs: 3000,
    });

    await router.push({ name: PageNames.Account });

  } catch (error: unknown) {
    console.error('Password update failed:', error);

    // Set an error message for the password field
    actions.setFieldError('password', extractErrorMessage(error));
  }
});



function togglePasswordVisibility(): void {
  isPasswordVisible.value = !isPasswordVisible.value
}
</script>

<template>
  <form @submit="onSubmit">
    <section class="account-form--content">
      <div class="fields">
        <!-- Hide first name and last name for accessibility -->
        <div v-show="false">
          <div class="field">
            <label for="firstName">First Name</label>
            <input id="firstName"
                   :value="applicationStore.activeUser?.forename"
                   type="text"
                   autocomplete="given-name"
                   disabled>
          </div>
          <div class="field">
            <label for="lastName">Last Name</label>
            <input id="lastName"
                   :value="applicationStore.activeUser?.surname"
                   type="text"
                   autocomplete="family-name"
                   disabled>
          </div>
        </div>

        <div class="field">
          <label for="currentPassword">Current Password</label>
          <input id="currentPassword"
                 v-model="currentPasswordValue"
                 :type="isPasswordVisible ? 'text' : 'password'"
                 autocomplete="current-password"
                 :class="{ 
                   'input-error': currentPasswordMeta.touched && !currentPasswordMeta.valid, 
                   'input-valid': currentPasswordMeta.touched && currentPasswordMeta.valid 
                 }"
                 @input="currentPasswordMeta.touched = true">
          <ErrorMessage name="currentPassword" class="message-error message" as="p" />
        </div>

        <div class="field">
          <label for="password">New Password</label>
          <input id="password"
                 v-model="passwordValue"
                 :type="isPasswordVisible ? 'text' : 'password'"
                 autocomplete="new-password"
                 :class="{ 
                   'input-error': passwordMeta.touched && !passwordMeta.valid,
                   'input-valid': passwordMeta.touched && passwordMeta.valid 
                 }"
                 @input="passwordMeta.touched = true">
          <ErrorMessage name="password" class="message-error message" as="p" />
        </div>

        <div class="field">
          <label for="confirmPassword">Confirm Password</label>
          <input id="confirmPassword"
                 v-model="confirmPasswordValue"
                 :type="isPasswordVisible ? 'text' : 'password'"
                 autocomplete="new-password"
                 :class="{ 
                   'input-error': confirmPasswordMeta.touched && !confirmPasswordMeta.valid,
                   'input-valid': confirmPasswordMeta.touched && confirmPasswordMeta.valid
                 }"
                 @input="confirmPasswordMeta.touched = true">
          <ErrorMessage name="confirmPassword" class="message-error message" as="p" />
        </div>
      </div>
    </section>

    <ButtonContainer justify-content="space-between">
      <ButtonComponent :type="ButtonType.Button"
                       :variant="ButtonVariant.Light"
                       :is-outline-btn="true"
                       :is-block-btn="true"
                       @click="togglePasswordVisibility">
        {{ isPasswordVisible ? 'Hide' : 'Show' }} Passwords
      </ButtonComponent>
      <ButtonComponent :disabled="!meta.valid"
                       :loading="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Update Password
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
